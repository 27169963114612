import { useTranslation } from "react-i18next";
import useLocalizedMorgueName from "../hooks/useLocalizedMorgueName";
import useMorgueData from "../hooks/useMorgueData";

interface Props {
  specificLogData: MorgueBedsUpdateLog;
}

const MorgueBedsUpdateLogTableItem = ({ specificLogData }: Props) => {
  const { t } = useTranslation();
  const {
    morgueId,
    newCurrentBeds,
    previousCurrentBeds,
    previousHazardousCurrent,
    newHazardousCurrent,
  } = specificLogData;
  const morgue = useMorgueData(morgueId);
  const morgueName = useLocalizedMorgueName(morgue?.name);

  const hasUpdatedNormalBeds = previousCurrentBeds !== newCurrentBeds;
  const hasUpdatedHazordousBeds =
    previousHazardousCurrent !== newHazardousCurrent;

  return (
    <>
      {`${t("Updated morgue", { morgueName })}\n${
        hasUpdatedNormalBeds
          ? `${t("Used spots")}: ${previousCurrentBeds} -> ${newCurrentBeds}\n`
          : ""
      }${
        hasUpdatedHazordousBeds
          ? `${t(
              "Used hazardous spots"
            )}: ${previousHazardousCurrent} -> ${newHazardousCurrent}\n`
          : ""
      }`}
    </>
  );
};

export default MorgueBedsUpdateLogTableItem;
