import { SetStateAction, useState } from "react";
import {
  Box,
  Button,
  Input,
  useToast,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import loginWithEmailLink from "../callableFunctions/loginWithEmailLink";
import { v4 } from "uuid";

const semiPersistentBrowserIdKey = "semiPersistentBrowserId";
export const emailForSignInKey = "emailForSignIn";

interface ErrorWithCode {
  code: string;
}

const isErrorWithCode = (error: unknown): error is ErrorWithCode => {
  if (
    typeof error === "object" &&
    error !== null &&
    Object.prototype.hasOwnProperty.call(error, "code") &&
    typeof (error as { code: string }).code === "string"
  ) {
    return true;
  }

  return false;
};

interface ErrorWithCodeAndDetails {
  code: string;
  details: { timeUntilNextAttempt: number };
}

const isErrorWithCodeAndDetails = (
  error: unknown
): error is ErrorWithCodeAndDetails => {
  if (
    typeof error === "object" &&
    error !== null &&
    Object.prototype.hasOwnProperty.call(error, "code") &&
    Object.prototype.hasOwnProperty.call(error, "details") &&
    typeof (error as { code: string }).code === "string" &&
    typeof (error as { details: { timeUntilNextAttempt: number } }).details ===
      "object" &&
    Object.prototype.hasOwnProperty.call(
      (error as { details: { timeUntilNextAttempt: number } }).details,
      "timeUntilNextAttempt"
    ) &&
    typeof (error as { details: { timeUntilNextAttempt: number } }).details
      .timeUntilNextAttempt === "number"
  ) {
    return true;
  }

  return false;
};

interface LinkAlreadySentToastDescriptionProps {
  timeUntilNextAttempt: number;
}

const LinkAlreadySentToastDescription = ({
  timeUntilNextAttempt,
}: LinkAlreadySentToastDescriptionProps) => {
  const { t } = useTranslation();
  return (
    <VStack mt={2} alignItems="flex-start">
      <Text>
        {t(
          "We've already sent you a link, please check out the following steps or try again in X seconds.",
          {
            timeUntilNextAttempt,
          }
        )}
      </Text>
      <Text>
        {t(
          "1. Check your spam folder. Depending on your mail client and possible firewall, it may be flagged as spam."
        )}
      </Text>
      <Text>
        {t(
          "2. Wait. Normally, the email arrives very quickly, but in extreme cases it may take up to 10 minutes."
        )}
      </Text>
    </VStack>
  );
};

const UserNotFoundToastDescription = () => {
  const { t } = useTranslation();
  return (
    <VStack mt={2} alignItems="flex-start">
      <Text>
        {t(
          "No user was found using the email address you provided. Please ensure the following:"
        )}
      </Text>
      <Text>
        {t(
          "1. Double-check that you have spelled your email address correctly."
        )}
      </Text>
      <Text>
        {t(
          "2. If you do not yet have an account, one must be created for you by your administrator."
        )}
      </Text>
    </VStack>
  );
};

const Login = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [semiPersistentBrowserId, setSemiPersistentBrowserId] =
    useState<string>();
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const handleChange = (event: { target: { value: SetStateAction<string> } }) =>
    setEmail(event.target.value);

  const submitForm = () => {
    let browserId = semiPersistentBrowserId;

    // If browserId cannot be retrieved from state, figure it out some other way.
    if (browserId === undefined) {
      let locallyStoredBrowserId = window.localStorage.getItem(
        semiPersistentBrowserIdKey
      );

      // Prefer re-using id from local storage.
      if (locallyStoredBrowserId !== null) {
        browserId = locallyStoredBrowserId;
      } else {
        browserId = v4(); // Generate new id if it could not be loaded from local storage.
      }
    }

    // Try persisting browser id to local storage.
    try {
      window.localStorage.setItem(semiPersistentBrowserIdKey, browserId);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }

    // Persist it also to the state. This way, even if local storage fails, we keep re-using the id until the user refreshes.
    setSemiPersistentBrowserId(browserId);

    setIsSendingEmail(true);
    loginWithEmailLink({ email, semiPersistentBrowserId: browserId })
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        try {
          window.localStorage.setItem(emailForSignInKey, email);
        } catch (error) {
          console.log(error);
          Sentry.captureException(error);
        }
        // ...
        toast({
          title: t("Email sent!"),
          description: t("We've sent you a link, check your email!"),
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
        });
      })
      .catch((error: unknown) => {
        Sentry.captureException(error);
        if (
          isErrorWithCodeAndDetails(error) &&
          error.code === "functions/resource-exhausted"
        ) {
          const { details } = error;
          const { timeUntilNextAttempt } = details;

          toast({
            title: t("Link already sent!"),
            description: (
              <LinkAlreadySentToastDescription
                timeUntilNextAttempt={timeUntilNextAttempt}
              />
            ),
            status: "warning",
            duration: null,
            isClosable: true,
          });
          return;
        }

        if (isErrorWithCode(error)) {
          const { code } = error;

          if (code === "functions/not-found") {
            toast({
              title: t("User not found!"),
              description: <UserNotFoundToastDescription />,
              status: "error",
              duration: null,
              isClosable: true,
            });
            return;
          }
        }

        toast({
          title: t("An error occurred!"),
          description: t("An error occurred, please try again."),
          status: "error",
          duration: null,
          isClosable: true,
        });
      })
      .finally(() => {
        setIsSendingEmail(false);
      });
  };
  return (
    <Box pt={20}>
      <Heading>{t("Log in")}</Heading>
      <Text mt={4} maxW="xl">
        {t(
          "Enter your email address and click the button below to receive a link for completing the log in. Please check your spam folder in case the email does not appear."
        )}
      </Text>
      <Text mt={2} maxW="xl">
        {t(
          "In order to log in, your administrator must first create an account for you."
        )}
      </Text>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          submitForm();
        }}
      >
        <Input
          id="email"
          name="email"
          mt={4}
          maxW="xl"
          value={email}
          type="email"
          autoComplete="on"
          autoFocus
          onChange={handleChange}
          placeholder={t("Enter your email address...")}
        />
        <Button
          isLoading={isSendingEmail}
          mt={2}
          display="block"
          type="submit"
          isDisabled={email.length === 0}
        >
          {t("Send link")}
        </Button>
      </form>
    </Box>
  );
};

export default Login;
