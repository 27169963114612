import {
  doc,
  arrayUnion,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { v4 } from "uuid";
import { db } from "../config/firebase";

const addUserToMorgue = ({
  createdBy,
  specificLogData,
}: {
  createdBy: UserLogData;
  specificLogData: MorgueUserUpdateLog;
}) => {
  const logId = v4();
  const log: Log = {
    id: logId,
    specificLogData,
    createdAt: serverTimestamp() as Timestamp,
    createdBy,
  };

  const batch = writeBatch(db);

  const logRef = doc(db, "logs", logId);
  batch.set(logRef, log);

  const morgueDocRef = doc(db, "morgues", specificLogData.morgueId);

  batch.update(morgueDocRef, {
    users: arrayUnion(specificLogData.updatedUser.uid),
    lastUpdated: serverTimestamp(),
  });

  return batch.commit();
};

export default addUserToMorgue;
