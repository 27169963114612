import { useNavigate, useParams } from "react-router-dom";
import { Box, Heading, SimpleGrid, Text, useToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import useAreaMorgues from "../hooks/useAreaMorgues";
import {
  useTranslation,
  withTranslation,
  WithTranslationProps,
} from "react-i18next";
import MorgueCard from "./MorgueCard";
import PageNotFound from "../components/PageNotFound";
import { useContext, useState } from "react";
import WelfareAreasDataContext from "../contexts/WelfareAreasDataContext";
import CreateMorgueDialog from "../morgues/CreateMorgueDialog";
import createMorgue from "../firestore/createMorgue";
import useIsAreaMaintainer from "../hooks/useIsAreaMaintainer";
import { useAuthenticatedUser } from "../contexts/AuthenticatedUserContext";

interface OccupiedBedsProps {
  morgues: Morgue[];
}

const OccupiedBeds = ({ morgues }: OccupiedBedsProps) => {
  const { t } = useTranslation();
  const { current, max, hazardousCurrent } = morgues.reduce(
    (acc, cur) => {
      acc.max += cur.beds.max;
      acc.current += cur.beds.current;
      acc.hazardousCurrent += cur.beds.hazardousCurrent;
      return acc;
    },
    { max: 0, current: 0, hazardousCurrent: 0 }
  );
  const totalCurrent = current + hazardousCurrent;

  return (
    <Heading mb={2} fontSize={"lg"} mt={10}>{`${t(
      "Total beds used within the district:"
    )} ${totalCurrent}/${max}`}</Heading>
  );
};

const MorguesPage = ({ i18n }: WithTranslationProps) => {
  const toast = useToast();
  const [isCreatingMorgue, setIsCreatingMorgue] = useState(false);
  const currentLanguage: Language =
    i18n !== undefined &&
    (i18n.language === "fi" || i18n.language === "sv" || i18n.language === "en")
      ? i18n.language
      : "fi";
  const { t } = useTranslation();
  const { area } = useParams();
  const welfareAreasData = useContext(WelfareAreasDataContext);
  const welfareArea = welfareAreasData.welfareAreas.find(
    (welfareArea) => welfareArea.id === area
  );
  const { userBaseInfo, uid } = useAuthenticatedUser();
  const navigate = useNavigate();
  const morgues = useAreaMorgues(area || "");
  const noMorguesAdded = morgues !== undefined && morgues.length === 0;
  const hasMorgues = !noMorguesAdded;
  const isAreaMaintainer = useIsAreaMaintainer(area || "");

  const createNewMorgue = async (saveData: MorgueSaveData) => {
    setIsCreatingMorgue(true);
    const { fiName, svName, enName, curBeds, maxBeds, hazardousCurrent } =
      saveData;
    try {
      const createdBy: Log["createdBy"] = {
        uid,
        ...userBaseInfo,
      };
      await createMorgue({
        createdBy,
        welfareArea: area || "",
        fi: fiName,
        sv: svName !== "" ? svName : fiName,
        en: enName !== "" ? enName : fiName,
        current: curBeds,
        max: maxBeds,
        hazardousCurrent,
      });
      toast({
        title: t("Creation successful!"),
        description: t("The morgue was successfully created!"),
        status: "success",
        duration: 10 * 1000,
        isClosable: true,
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        title: t("An error occurred!"),
        description: t(
          "An error occurred when creating the morgue, please try again!"
        ),
        status: "error",
        duration: 30 * 1000,
        isClosable: true,
      });
    } finally {
      setIsCreatingMorgue(false);
    }
  };

  return morgues === undefined || welfareArea === undefined ? (
    <PageNotFound />
  ) : (
    <Box py={10}>
      <Heading>{welfareArea.name[currentLanguage]}</Heading>
      <Text mt={2}>
        {noMorguesAdded
          ? t("There are no morgues in this area yet.")
          : t("You can view all the morgues within the area below.")}
      </Text>
      {hasMorgues ? <OccupiedBeds morgues={morgues} /> : null}
      {isAreaMaintainer ? (
        <CreateMorgueDialog
          isSaving={isCreatingMorgue}
          isDisabled={isCreatingMorgue}
          onSave={createNewMorgue}
        />
      ) : null}
      {hasMorgues ? (
        <SimpleGrid minChildWidth={"300px"} spacing={10} alignItems="center">
          {morgues.map((morgue) => {
            return (
              <MorgueCard
                onClick={() => navigate(`/areas/${area}/morgues/${morgue.id}`)}
                morgue={morgue}
                key={morgue.id}
                welfareAreaId={area || ""}
              />
            );
          })}
        </SimpleGrid>
      ) : null}
    </Box>
  );
};

export default withTranslation()(MorguesPage);
