import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  FormHelperText,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface CreateMorgueDialogProps {
  isSaving?: boolean;
  isDisabled?: boolean;
  onSave(data: MorgueSaveData): void;
}

const initialName = "";
const initialMaxBeds = 10;

const CreateMorgueDialog = ({
  isSaving,
  isDisabled,
  onSave,
}: CreateMorgueDialogProps) => {
  const morgueFormId = "create-morgue-form";
  const [fiName, setFiName] = useState(initialName);
  const [svName, setSvName] = useState(initialName);
  const [enName, setEnName] = useState(initialName);
  const [maxBeds, setMaxBeds] = useState(initialMaxBeds);
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  useEffect(() => {
    if (!isOpen) {
      setFiName(initialName);
      setSvName(initialName);
      setEnName(initialName);
      setMaxBeds(initialMaxBeds);
    }
  }, [isOpen]);

  const isFiNameUnchanged = fiName === "";
  const isSvNameUnchanged = svName === "";
  const isEnNameUnchanged = enName === "";
  const isMaxBedsUnchanged = maxBeds === initialMaxBeds;

  const hasNoChanges =
    isFiNameUnchanged &&
    isSvNameUnchanged &&
    isEnNameUnchanged &&
    isMaxBedsUnchanged;

  const isSomeNameValid = fiName !== "" || svName !== "" || enName !== "";

  return (
    <>
      <Button mb={6} onClick={onOpen}>
        {t("Create morgue")}
      </Button>
      <AlertDialog
        size="6xl"
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("Create morgue")}
            </AlertDialogHeader>
            <AlertDialogBody>
              <Text maxW="660px">
                {t(
                  "You can add a new morgue to the welfare area by filling out the details below. You must provide a name for the morgue in at least one language."
                )}
              </Text>
              <form id={morgueFormId} style={{ marginTop: "24px" }}>
                <Box
                  display="flex"
                  flexDirection={["column", null, null, "row"]}
                >
                  <FormControl
                    isDisabled={isDisabled}
                    mr={{ sm: 0, lg: 4 }}
                    mb={{ sm: 4, lg: 0 }}
                    flex={1}
                  >
                    <FormLabel>{t("Finnish name")}</FormLabel>
                    <Input
                      placeholder={t("The Finnish name of the morgue...")}
                      value={fiName}
                      onChange={(event) => setFiName(event.target.value)}
                    />
                  </FormControl>
                  <FormControl
                    mr={{ sm: 0, lg: 4 }}
                    mb={{ sm: 4, lg: 0 }}
                    flex={1}
                    isDisabled={isDisabled}
                  >
                    <FormLabel>{t("Swedish name")}</FormLabel>
                    <Input
                      placeholder={t("The Swedish name of the morgue...")}
                      value={svName}
                      onChange={(event) => setSvName(event.target.value)}
                    />
                  </FormControl>
                  <FormControl flex={1} isDisabled={isDisabled}>
                    <FormLabel>{t("English name")}</FormLabel>
                    <Input
                      placeholder={t("The English name of the morgue...")}
                      value={enName}
                      onChange={(event) => setEnName(event.target.value)}
                    />
                  </FormControl>
                </Box>
                <FormControl
                  mt={6}
                  maxW={"400px"}
                  isDisabled={isDisabled}
                  isRequired
                >
                  <FormLabel>{t("Maximum spots")}</FormLabel>
                  <NumberInput
                    min={1}
                    max={9999999}
                    value={maxBeds || (maxBeds === 0 ? 0 : "")}
                    onChange={(_valueAsString, valueAsNumber) =>
                      setMaxBeds(valueAsNumber)
                    }
                  >
                    <NumberInputField />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                  <FormHelperText>
                    {t("The total number of beds in this morgue")}
                  </FormHelperText>
                </FormControl>
              </form>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {t("Cancel")}
              </Button>
              <Button
                ml={3}
                isDisabled={isDisabled || hasNoChanges || !isSomeNameValid}
                onClick={() => {
                  onSave({
                    fiName,
                    svName,
                    enName,
                    curBeds: 0,
                    maxBeds,
                    hazardousCurrent: 0,
                  });
                  onClose();
                }}
                form={morgueFormId}
                isLoading={isSaving}
              >
                {t("Create")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default CreateMorgueDialog;
