import { Tr, Td, Text } from "@chakra-ui/react";
import MorgueBedsUpdateLogTableItem from "./LogTableItems/MorgueBedsUpdateLogTableItem";
import MorgueCreateLogTableItem from "./LogTableItems/MorgueCreateLogTableItem";
import MorgueSaveDataUpdateLogTableItem from "./LogTableItems/MorgueSaveDataUpdateLogTableItem";
import MorgueUserUpdateLogTableItem from "./LogTableItems/MorgueUserUpdateLogTableItem";
import WelfareAreaMaintainerUpdateLogTableItem from "./LogTableItems/WelfareAreaMaintainerUpdateLogTableItem";
import WelfareAreaUserUpdateLogTableItem from "./LogTableItems/WelfareAreaUserUpdateLogTableItem";
import createDateString from "./utils/createDateString";
import userNameString from "./utils/userNameString";

interface Props {
  log: Log;
}

const LogItemRenderer = ({ log }: Props) => {
  const { specificLogData, ...rest } = log;
  const { createdAt, createdBy } = rest;
  const { type } = specificLogData;

  const createdByString = userNameString(createdBy);

  const dateString = createDateString(createdAt.toDate());

  const hasMostRecentVersion =
    type === "morgueBedsUpdateLog" ||
    type === "morgueSaveDataUpdateLog" ||
    type === "morgueUserUpdateLog" ||
    type === "welfareAreaMaintainerUpdateLog" ||
    type === "welfareAreaUserUpdateLog" ||
    type === "morgueCreateLog";

  return hasMostRecentVersion ? (
    <Tr>
      <Td>{dateString}</Td>
      <Td>
        {createdByString === createdBy.email ? (
          createdByString
        ) : (
          <>
            <Text>{createdByString}</Text>
            <Text fontSize="xs">{createdBy.email}</Text>
          </>
        )}
      </Td>
      <Td>
        {type === "morgueBedsUpdateLog" ? (
          <MorgueBedsUpdateLogTableItem specificLogData={specificLogData} />
        ) : type === "morgueSaveDataUpdateLog" ? (
          <MorgueSaveDataUpdateLogTableItem specificLogData={specificLogData} />
        ) : type === "morgueUserUpdateLog" ? (
          <MorgueUserUpdateLogTableItem specificLogData={specificLogData} />
        ) : type === "welfareAreaMaintainerUpdateLog" ? (
          <WelfareAreaMaintainerUpdateLogTableItem
            specificLogData={specificLogData}
          />
        ) : type === "welfareAreaUserUpdateLog" ? (
          <WelfareAreaUserUpdateLogTableItem
            specificLogData={specificLogData}
          />
        ) : (
          <MorgueCreateLogTableItem specificLogData={specificLogData} />
        )}
      </Td>
    </Tr>
  ) : null;
};

export default LogItemRenderer;
