import { Text, Box, Heading, useToast, Button } from "@chakra-ui/react";
import { useState } from "react";
import {
  useTranslation,
  withTranslation,
  WithTranslationProps,
} from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import PageNotFound from "../components/PageNotFound";
import { useAuthenticatedUser } from "../contexts/AuthenticatedUserContext";
import addUserToMorgue from "../firestore/addUserToMorgue";
import removeUserFromMorgue from "../firestore/removeUserFromMorgue";
import updateMorgueData from "../firestore/updateMorgueData";
import useLocalizedMorgueName from "../hooks/useLocalizedMorgueName";
import useMorgueData from "../hooks/useMorgueData";
import MorgueForm from "../morgues/MorgueForm";
import MorgueSelectUser from "../morgues/MorgueSelectUser";
import CollapsibleInstructions from "../components/CollapsibleInstructions";
import { scribeHowLinks } from "../config/values";

const MorguePage = ({ i18n }: WithTranslationProps) => {
  const navigate = useNavigate();
  const currentUser = useAuthenticatedUser();
  const [isAddingUser, setisAddingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();
  const { morgue: morgueId, area: welfareAreaId } = useParams();
  const morgue = useMorgueData(morgueId || "");
  const failedToLoadMorgue = morgue === undefined;
  const { language } = currentUser;

  const localizedName = useLocalizedMorgueName(morgue?.name);

  const handleSave = async (data: MorgueSaveData) => {
    /*
    TODO: Replace saving with Transaction.

    The idea is that one should use a transaction, and first read the morgue
    document. This way, we properly handle multiple updates at the same time
    (unlikely, but possible), and can also compare the `curBeds` value in the 
    argument data to the relevant value in the document. In case `curBeds` is
    different from what is in the document, we should also update `lastUpdated`
    in `beds`.
    */
    if (morgue && welfareAreaId) {
      setIsSaving(true);
      const specificLogData: MorgueSaveDataUpdateLog = {
        morgueId: morgue.id,
        newSaveData: data,
        prevSaveData: {
          curBeds: morgue.beds.current,
          maxBeds: morgue.beds.max,
          enName: morgue.name.en,
          fiName: morgue.name.fi,
          svName: morgue.name.sv,
          hazardousCurrent: morgue.beds.hazardousCurrent,
        },
        type: "morgueSaveDataUpdateLog",
        welfareAreaId,
      };
      const createdBy: Log["createdBy"] = {
        email: currentUser.userBaseInfo.email,
        lastName: currentUser.userBaseInfo.lastName,
        firstName: currentUser.userBaseInfo.firstName,
        uid: currentUser.uid,
      };
      try {
        await updateMorgueData({
          specificLogData,
          createdBy,
        });
        toast({
          title: t("Update successful!"),
          description: t("The morgue was successfully updated!"),
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
        });
      } catch (error) {
        Sentry.captureException(error);
        toast({
          title: t("An error occurred!"),
          description: t(
            "An error occurred when updating the morgue, please try again!"
          ),
          status: "error",
          duration: 30 * 1000,
          isClosable: true,
        });
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleAddUser = async (userToAdd: UserLogData) => {
    /*
    TODO: Replace saving with Transaction.

    The idea is that one should use a transaction, and first read the morgue
    document. This way, we properly handle multiple updates at the same time,
    and add a user to the document.
    */
    if (morgueId && welfareAreaId) {
      setisAddingUser(true);
      try {
        await addUserToMorgue({
          createdBy: {
            email: currentUser.userBaseInfo.email,
            uid: currentUser.uid,
            firstName: currentUser.userBaseInfo.firstName,
            lastName: currentUser.userBaseInfo.lastName,
          },
          specificLogData: {
            isUpdatedToMorgueUser: true,
            morgueId,
            type: "morgueUserUpdateLog",
            updatedUser: userToAdd,
            welfareAreaId,
          },
        });
        toast({
          title: t("Update successful!"),
          description: t("The morgue was successfully updated!"),
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
        });
      } catch (error) {
        Sentry.captureException(error);
        toast({
          title: t("An error occurred!"),
          description: t(
            "An error occurred when updating the morgue, please try again!"
          ),
          status: "error",
          duration: 30 * 1000,
          isClosable: true,
        });
      } finally {
        setisAddingUser(false);
      }
    }
  };

  const handleDeleteUser = async (userToAdd: UserLogData) => {
    /*
    TODO: Replace saving with Transaction.

    The idea is that one should use a transaction, and first read the morgue
    document. This way, we properly handle multiple updates at the same time,
    and add a user to the document.
    */
    if (morgueId && welfareAreaId) {
      setIsDeletingUser(true);
      try {
        await removeUserFromMorgue({
          createdBy: {
            email: currentUser.userBaseInfo.email,
            uid: currentUser.uid,
            firstName: currentUser.userBaseInfo.firstName,
            lastName: currentUser.userBaseInfo.lastName,
          },
          specificLogData: {
            isUpdatedToMorgueUser: false,
            morgueId,
            type: "morgueUserUpdateLog",
            updatedUser: userToAdd,
            welfareAreaId,
          },
        });
        toast({
          title: t("Update successful!"),
          description: t("The morgue was successfully updated!"),
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
        });
      } catch (error) {
        Sentry.captureException(error);
        toast({
          title: t("An error occurred!"),
          description: t(
            "An error occurred when updating the morgue, please try again!"
          ),
          status: "error",
          duration: 30 * 1000,
          isClosable: true,
        });
      } finally {
        setIsDeletingUser(false);
      }
    }
  };

  const onLogsClick = () => {
    navigate("logs");
  };

  return failedToLoadMorgue ? (
    <PageNotFound />
  ) : (
    <Box py={10}>
      <Heading>{localizedName}</Heading>
      <Text mt={2}>
        {t("Here you can view and edit the details of the morgue.")}
      </Text>
      <CollapsibleInstructions
        text={t("How do I edit a morgue?")}
        url={scribeHowLinks.editMorgueAdmin[language]}
      />
      <MorgueForm
        morgueData={morgue}
        isSaving={isSaving}
        onSave={handleSave}
        isDisabled={isSaving || isAddingUser || isDeletingUser}
      />
      <Button onClick={onLogsClick} mt={4}>
        {t("Event log")}
      </Button>
      <MorgueSelectUser
        isSaving={isAddingUser || isDeletingUser}
        onAddUser={handleAddUser}
        onRemoveUser={handleDeleteUser}
        isDisabled={isSaving || isAddingUser || isDeletingUser}
      />
    </Box>
  );
};

export default withTranslation()(MorguePage);
