import { onSnapshot, where, collection, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../config/firebase";
import { useUserContext } from "../contexts/UserContext";

const useUserWelfareAreas = () => {
  const user = useUserContext();
  const [welfareAreas, setWelfareAreas] = useState<
    WelfareArea[] | null | undefined
  >();

  useEffect(() => {
    if (user?.uid) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, "welfareAreas"),
          where(`users.${user.uid}`, "!=", null)
        ),
        (snapshot) => {
          const fetchedWelfareAreas = snapshot.docs.map(
            (doc) => doc.data({ serverTimestamps: "estimate" }) as WelfareArea
          );
          setWelfareAreas(fetchedWelfareAreas);
        },
        (error) => {
          console.error({ error });
          setWelfareAreas(null);
        }
      );

      return unsubscribe;
    }
  }, [user?.uid]);

  return welfareAreas;
};

export default useUserWelfareAreas;
