import { httpsCallable } from "firebase/functions";
import { functions } from "../config/firebase";

const addWelfareAreaUsers = async ({
  welfareAreaId,
  emails,
}: {
  welfareAreaId: string;
  emails: string[];
}) => {
  const addUsers = httpsCallable(functions, "addWelfareAreaUsers");

  const response = await addUsers({
    welfareAreaId,
    emails,
  });
  const data = response.data;
  return data;
};

export default addWelfareAreaUsers;
