import { useContext } from "react";
import { useUserContext } from "../contexts/UserContext";
import WelfareAreasDataContext from "../contexts/WelfareAreasDataContext";

const useIsAreaMaintainer = (area: string) => {
  const user = useUserContext();
  const welfareAreaData = useContext(WelfareAreasDataContext);
  return !!welfareAreaData.welfareAreas.find(
    (welfareArea) =>
      welfareArea.id === area &&
      user?.uid !== undefined &&
      (welfareArea.admin === user.uid ||
        welfareArea.maintainers.includes(user.uid))
  );
};

export default useIsAreaMaintainer;
