import { onSnapshot, query, collection, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../config/firebase";

const useMorgues = (welfareAreaIds: string[]) => {
  const [morgues, setMorgues] = useState<Morgue[] | null | undefined>();

  useEffect(() => {
    if (welfareAreaIds.length > 0) {
      const unsubscribe = onSnapshot(
        query(
          collection(db, "morgues"),
          where("welfareArea", "in", welfareAreaIds) // Does this allow more than 10 areas? This is highly unlikely of occurring tho.
        ),
        (snapshot) => {
          const fetchedMorgues = snapshot.docs.map(
            (doc) => doc.data({ serverTimestamps: "estimate" }) as Morgue
          );
          setMorgues(fetchedMorgues);
        },
        (error) => {
          console.error({ error });
          setMorgues(null);
        }
      );

      return unsubscribe;
    }
  }, [welfareAreaIds]);

  return morgues;
};

export default useMorgues;
