import { Box, Heading, Text, Button, useToast, VStack } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import {
  WithTranslationProps,
  withTranslation,
  useTranslation,
} from "react-i18next";
import { useAuthenticatedUser } from "../contexts/AuthenticatedUserContext";
import incrementCurrentMorgueBeds from "../firestore/incrementCurrentMorgueBeds";
import useIsAreaAdmin from "../hooks/useIsAreaAdmin";
import useIsAreaMaintainer from "../hooks/useIsAreaMaintainer";
import useIsMorgueUser from "../hooks/useIsMorgueUser";
import useLocalizedMorgueName from "../hooks/useLocalizedMorgueName";
import createDateString from "../utils/createDateString";
import MorgueUpdateBedsControls from "./MorgueUpdateBedsControls";

interface MorgueCardProps extends WithTranslationProps {
  onClick(morgueId: string): void;
  morgue: Morgue;
  welfareAreaId: string;
}

const MorgueCard = ({ onClick, morgue, welfareAreaId }: MorgueCardProps) => {
  const currentUser = useAuthenticatedUser();
  const isWelfareAreaAdmin = useIsAreaAdmin(welfareAreaId);
  const isWelfareAdminMaintainer = useIsAreaMaintainer(welfareAreaId);
  const isMorgueUser = useIsMorgueUser(morgue.id);
  const { t } = useTranslation();
  const { beds, name, id } = morgue;
  const isAbleToEditMorgue = isWelfareAdminMaintainer || isWelfareAreaAdmin;
  const toast = useToast();
  const [isUpdatingMorgues, setIsUpdatingMorgues] = useState(false);
  const {
    current: dbCurrentBeds,
    max: dbMaxBeds,
    lastUpdated,
    hazardousCurrent: dbHazardousCurrent,
  } = beds;

  const [lagCompensatedCurrentBeds, setLagCompensatedCurrentBeds] =
    useState(dbCurrentBeds);
  const [
    lagCompensatedHazardousCurrentBeds,
    setLagCompensatedHazardousCurrentBeds,
  ] = useState(dbHazardousCurrent);

  const localizedName = useLocalizedMorgueName(name);
  const bedRatio = lagCompensatedCurrentBeds / dbMaxBeds;
  const capacityColor =
    bedRatio >= 0.8 ? "red.600" : bedRatio >= 0.5 ? "orange.400" : "green.400";

  const handleUpdateBeds = useMemo(
    () =>
      debounce(async (newCurrentBeds: number, newHazardousCurrent: number) => {
        setIsUpdatingMorgues(true);
        try {
          await incrementCurrentMorgueBeds({
            createdBy: {
              email: currentUser.userBaseInfo.email,
              firstName: currentUser.userBaseInfo.firstName,
              lastName: currentUser.userBaseInfo.lastName,
              uid: currentUser.uid,
            },
            specificLogData: {
              newCurrentBeds,
              previousCurrentBeds: dbCurrentBeds,
              newHazardousCurrent,
              previousHazardousCurrent: dbHazardousCurrent,
              morgueId: morgue.id,
              type: "morgueBedsUpdateLog",
              welfareAreaId,
            },
          });
          toast({
            title: t("Update successful!"),
            description: t("The morgue was successfully updated!"),
            status: "success",
            duration: 10 * 1000,
            isClosable: true,
          });
        } catch (error) {
          console.log({ error });

          Sentry.captureException(error);
          toast({
            title: t("An error occurred!"),
            description: t(
              "An error occurred when updating the morgue, please try again!"
            ),
            status: "error",
            duration: 10 * 1000,
            isClosable: true,
          });
          // Reset beds in UI if writing fails.
          setLagCompensatedCurrentBeds(dbCurrentBeds);
          setLagCompensatedHazardousCurrentBeds(dbHazardousCurrent);
        } finally {
          setIsUpdatingMorgues(false);
        }
      }, 1 * 1000),
    [
      currentUser.uid,
      currentUser.userBaseInfo.email,
      currentUser.userBaseInfo.firstName,
      currentUser.userBaseInfo.lastName,
      dbCurrentBeds,
      dbHazardousCurrent,
      morgue.id,
      t,
      toast,
      welfareAreaId,
    ]
  );

  const handleIncrementCurrentBeds = () => {
    const newLagCompensatedCurrentBeds = lagCompensatedCurrentBeds + 1;
    setLagCompensatedCurrentBeds(newLagCompensatedCurrentBeds);
    handleUpdateBeds(
      newLagCompensatedCurrentBeds,
      lagCompensatedHazardousCurrentBeds
    );
  };

  const handleDecrementCurrentBeds = () => {
    const newLagCompensatedCurrentBeds = lagCompensatedCurrentBeds - 1;
    setLagCompensatedCurrentBeds(newLagCompensatedCurrentBeds);
    handleUpdateBeds(
      newLagCompensatedCurrentBeds,
      lagCompensatedHazardousCurrentBeds
    );
  };

  const handleIncrementCurrentHazardousBeds = () => {
    const newLagCompensatedHazardousCurrentBeds =
      lagCompensatedHazardousCurrentBeds + 1;
    setLagCompensatedHazardousCurrentBeds(
      newLagCompensatedHazardousCurrentBeds
    );
    handleUpdateBeds(
      lagCompensatedCurrentBeds,
      newLagCompensatedHazardousCurrentBeds
    );
  };

  const handleDecrementCurrentHazardousBeds = () => {
    const newLagCompensatedHazardousCurrentBeds =
      lagCompensatedHazardousCurrentBeds - 1;
    setLagCompensatedHazardousCurrentBeds(
      newLagCompensatedHazardousCurrentBeds
    );
    handleUpdateBeds(
      lagCompensatedCurrentBeds,
      newLagCompensatedHazardousCurrentBeds
    );
  };

  const lastUpdatedDate = lastUpdated.toDate();
  const dateString = createDateString(lastUpdatedDate);

  const bedsInUse =
    lagCompensatedCurrentBeds + lagCompensatedHazardousCurrentBeds;

  return (
    <Box
      shadow="lg"
      alignSelf="flex-start"
      display="flex"
      rounded={"lg"}
      flexDirection="column"
    >
      <Box roundedTop={"lg"} h="100px" w={"100%"} bgColor={capacityColor} />
      <Heading my={2} mx={2} fontSize="lg" noOfLines={1}>
        {localizedName}
      </Heading>
      {isAbleToEditMorgue ? (
        <>
          <Text fontWeight={"medium"} mx={2}>{`${t(
            "Used beds:"
          )} ${bedsInUse}/${dbMaxBeds}`}</Text>
          <Text fontWeight={"medium"} mx={2}>{`${t(
            "Used hazardous spots:"
          )} ${lagCompensatedHazardousCurrentBeds}`}</Text>
        </>
      ) : null}
      <Text mb={4} mx={2} fontSize="sm" color="gray.700">{`${t(
        "Last updated:"
      )} ${dateString}`}</Text>

      {isAbleToEditMorgue ? (
        <Button onClick={() => onClick(id)} mb={4} mx={2}>
          {t("Edit")}
        </Button>
      ) : (
        <VStack spacing={1}>
          <MorgueUpdateBedsControls
            isDecrementDisabled={
              !isMorgueUser ||
              lagCompensatedCurrentBeds <= 0 ||
              isUpdatingMorgues
            }
            decrementIconAriaLabel={t("Decrement currently used beds")}
            onDecrementIconClick={handleDecrementCurrentBeds}
            currentBeds={bedsInUse}
            maxBeds={dbMaxBeds}
            isIncrementDisabled={
              !isMorgueUser || bedsInUse >= dbMaxBeds || isUpdatingMorgues
            }
            incrementIconAriaLabel={t("Increment currently used beds")}
            onIncrementIconClick={handleIncrementCurrentBeds}
            headingText={t("Used beds")}
          />
          <MorgueUpdateBedsControls
            isDecrementDisabled={
              !isMorgueUser ||
              lagCompensatedHazardousCurrentBeds <= 0 ||
              isUpdatingMorgues
            }
            decrementIconAriaLabel={t("Decrement currently used beds")}
            onDecrementIconClick={handleDecrementCurrentHazardousBeds}
            currentBeds={lagCompensatedHazardousCurrentBeds}
            isIncrementDisabled={
              !isMorgueUser || bedsInUse >= dbMaxBeds || isUpdatingMorgues
            }
            incrementIconAriaLabel={t("Increment currently used beds")}
            onIncrementIconClick={handleIncrementCurrentHazardousBeds}
            headingText={t("Used hazardous spots")}
          />
        </VStack>
      )}
    </Box>
  );
};

export default withTranslation()(MorgueCard);
