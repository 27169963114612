import { VStack } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import WelfareAreasDataContext from "../contexts/WelfareAreasDataContext";
import useMorgueLogs from "../hooks/useMorgueLogs";
import LogsTable from "../LogsTable";

const MorgueLogsPage = () => {
  const { t } = useTranslation();
  const { morgue } = useParams();
  const welfareAreasData = useContext(WelfareAreasDataContext);
  const welfareAreaId = welfareAreasData.morgues.find(
    (morgueData) => morgueData.id === morgue
  )?.welfareArea;
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { logs, fetch, canFetchMore } = useMorgueLogs(
    morgue || "",
    welfareAreaId || ""
  );

  useEffect(() => {
    if (isFirstRender) {
      fetch();
      setIsFirstRender(false);
    }
  }, [fetch, isFirstRender]);

  return (
    <VStack py={10} spacing={8} alignItems="flex-start">
      {logs !== undefined && logs !== null ? (
        <LogsTable
          footerButton={{
            text: t("Show more"),
            disabled: !canFetchMore,
            onClick: fetch,
          }}
          logs={logs}
        />
      ) : null}
    </VStack>
  );
};

export default MorgueLogsPage;
