import { useContext } from "react";
import WelfareAreasDataContext from "../contexts/WelfareAreasDataContext";

const useMorgueData = (morgueId: string): Morgue | undefined => {
  const welfareAreaData = useContext(WelfareAreasDataContext);
  const morgue = welfareAreaData.morgues.find(
    (morgue) => morgue.id === morgueId
  );

  return morgue;
};

export default useMorgueData;
