import { useContext } from "react";
import WelfareAreasDataContext from "../contexts/WelfareAreasDataContext";

const useWelfareAreaUsers = (welfareAreaId: string): WelfareAreaUsers => {
  const welfareAreaData = useContext(WelfareAreasDataContext);
  return (
    welfareAreaData.welfareAreas.find(
      (welfareArea) => welfareArea.id === welfareAreaId
    )?.users || {}
  );
};

export default useWelfareAreaUsers;
