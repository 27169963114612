import { doc, serverTimestamp, writeBatch } from "firebase/firestore";
import { v4 } from "uuid";
import { db } from "../config/firebase";

const createMorgue = ({
  welfareArea,
  fi,
  sv,
  en,
  max,
  current,
  createdBy,
  hazardousCurrent,
}: {
  welfareArea: string;
  fi: Morgue["name"]["fi"];
  sv: Morgue["name"]["sv"];
  en: Morgue["name"]["en"];
  max: Morgue["beds"]["max"];
  current: Morgue["beds"]["current"];
  createdBy: Log["createdBy"];
  hazardousCurrent: Morgue["beds"]["hazardousCurrent"];
}) => {
  const morgueId = v4();
  const logId = v4();
  const specificLogData: MorgueCreateLog = {
    morgueId,
    type: "morgueCreateLog",
    welfareAreaId: welfareArea,
  };
  const log: Log = {
    id: logId,
    createdAt: serverTimestamp() as Timestamp,
    createdBy,
    specificLogData,
  };

  const batch = writeBatch(db);

  const logRef = doc(db, "logs", logId);
  batch.set(logRef, log);

  const morgue: Morgue = {
    id: morgueId,
    welfareArea,
    users: [],
    lastUpdated: serverTimestamp() as Timestamp,
    name: {
      fi,
      sv,
      en,
    },
    beds: {
      max,
      current,
      lastUpdated: serverTimestamp() as Timestamp,
      hazardousCurrent,
    },
  };

  const morgueDocRef = doc(db, "morgues", morgueId);
  batch.set(morgueDocRef, morgue);

  return batch.commit();
};

export default createMorgue;
