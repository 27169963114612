import { Box, BoxProps } from "@chakra-ui/react";
import Lottie from "lottie-react";
import loadingSkeleton from "../assets/loading-skeleton.json";

const LoadingSkeleton = (props: BoxProps) => {
  return (
    <Box display="flex" flexDir={"column"} alignItems={"center"} {...props}>
      <Lottie animationData={loadingSkeleton} loop={true} />
    </Box>
  );
};

export default LoadingSkeleton;
