import { useTranslation } from "react-i18next";
import userNameString from "../utils/userNameString";

interface Props {
  specificLogData: WelfareAreaUserUpdateLog;
}

const WelfareAreaUserUpdateLogTableItem = ({ specificLogData }: Props) => {
  const { t } = useTranslation();

  const { isAddedToWelfareArea, updatedUser } = specificLogData;

  const eventText = t(
    isAddedToWelfareArea
      ? "Added to welfare area"
      : "Removed from welfare area",
    { name: userNameString(updatedUser) }
  );

  return <>{eventText}</>;
};

export default WelfareAreaUserUpdateLogTableItem;
