import { httpsCallable } from "firebase/functions";
import { functions } from "../config/firebase";

const loginWithEmailLink = async ({
  email,
  semiPersistentBrowserId,
}: {
  email: string;
  semiPersistentBrowserId: string;
}) => {
  const sendEmail = httpsCallable(functions, "loginWithEmailLinkV2");

  const response = await sendEmail({
    email,
    origin: window.location.origin,
    semiPersistentBrowserId,
  });
  const data = response.data;
  return data;
};

export default loginWithEmailLink;
