import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LogItemRenderer from "./LogItemRenderer";

interface Props {
  logs: Log[];
  footerButton?: {
    text: string;
    onClick: () => void;
    disabled?: boolean;
  };
}

const LogsTable = ({ logs, footerButton }: Props) => {
  const { t } = useTranslation();
  return (
    <TableContainer whiteSpace="pre-wrap" width={"100%"}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>{t("Time")}</Th>
            <Th>{t("Person")}</Th>
            <Th>{t("Event")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {logs?.map((log) => (
            <LogItemRenderer key={log.id} log={log}></LogItemRenderer>
          ))}
        </Tbody>
      </Table>
      {footerButton !== undefined ? (
        <Button
          m={2}
          variant="ghost"
          disabled={footerButton.disabled}
          onClick={footerButton.onClick}
        >
          {footerButton.text}
        </Button>
      ) : null}
    </TableContainer>
  );
};

export default LogsTable;
