import {
  Box,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Button,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface MorgueFormProps {
  morgueData: Morgue;
  isSaving?: boolean;
  isDisabled?: boolean;
  onSave(data: MorgueSaveData): void;
}

const MorgueForm = ({
  morgueData,
  isSaving,
  isDisabled,
  onSave,
}: MorgueFormProps) => {
  const morgueFormId = `morgue-form-${morgueData.id}`;
  const { t } = useTranslation();
  const [fiName, setFiName] = useState(morgueData.name.fi);
  const [svName, setSvName] = useState(morgueData.name.sv);
  const [enName, setEnName] = useState(morgueData.name.en);
  const [curBeds, setCurBeds] = useState(morgueData.beds.current);
  const [maxBeds, setMaxBeds] = useState(morgueData.beds.max);
  const [curHazardousBeds, setCurHazardousBeds] = useState(
    morgueData.beds.hazardousCurrent
  );
  const [isBedFocused, setIsBedFocused] = useState(false);
  const handleOnFocusBedInput = () => setIsBedFocused(true);
  const handleOnBlurBedInput = () => setIsBedFocused(false);

  const bedsErrorCode: BedInputErrorCode | undefined = isBedFocused
    ? undefined
    : maxBeds < curBeds + curHazardousBeds
    ? "cur-max-mismatch"
    : undefined;

  /*
  Sync current state with prop if prop changes.
  In practice, this means someone else has saved changes
  to the Morgue while this user is editing the morgue.

  In that case, they lose their changes, but that should presumably be rare.
  */
  useEffect(() => {
    setFiName(morgueData.name.fi);
    setSvName(morgueData.name.sv);
    setEnName(morgueData.name.en);
    setCurBeds(morgueData.beds.current);
    setMaxBeds(morgueData.beds.max);
    setCurHazardousBeds(morgueData.beds.hazardousCurrent);
  }, [morgueData]);

  const isFiNameUnchanged = morgueData.name.fi === fiName;
  const isSvNameUnchanged = morgueData.name.sv === svName;
  const isEnNameUnchanged = morgueData.name.en === enName;
  const isCurBedsUnchanged = morgueData.beds.current === curBeds;
  const isMaxBedsUnchanged = morgueData.beds.max === maxBeds;
  const isCurHazardousBedsUnchanged =
    morgueData.beds.hazardousCurrent === curHazardousBeds;
  const hasNoChanges =
    isFiNameUnchanged &&
    isSvNameUnchanged &&
    isEnNameUnchanged &&
    isCurBedsUnchanged &&
    isMaxBedsUnchanged &&
    isCurHazardousBedsUnchanged;

  const morgueHasNotAName =
    fiName.length === 0 && svName.length === 0 && enName.length === 0;

  return (
    <form id={morgueFormId} style={{ marginTop: "24px" }}>
      <Box display="flex" flexDirection={["column", null, null, "row"]}>
        <FormControl
          isDisabled={isDisabled}
          mr={{ sm: 0, lg: 4 }}
          mb={{ sm: 4, lg: 0 }}
          flex={1}
        >
          <FormLabel>{t("Finnish name")}</FormLabel>
          <Input
            placeholder={t("The Finnish name of the morgue...")}
            value={fiName}
            onChange={(event) => setFiName(event.target.value)}
          />
        </FormControl>
        <FormControl
          mr={{ sm: 0, lg: 4 }}
          mb={{ sm: 4, lg: 0 }}
          flex={1}
          isDisabled={isDisabled}
        >
          <FormLabel>{t("Swedish name")}</FormLabel>
          <Input
            placeholder={t("The Swedish name of the morgue...")}
            value={svName}
            onChange={(event) => setSvName(event.target.value)}
          />
        </FormControl>
        <FormControl flex={1} isDisabled={isDisabled}>
          <FormLabel>{t("English name")}</FormLabel>
          <Input
            placeholder={t("The English name of the morgue...")}
            value={enName}
            onChange={(event) => setEnName(event.target.value)}
          />
        </FormControl>
      </Box>
      <Box display="flex" flexDirection={["column", null, null, "row"]} mt={6}>
        <FormControl
          mr={{ sm: 0, lg: 4 }}
          mb={{ sm: 4, lg: 0 }}
          maxW={"400px"}
          isDisabled={isDisabled}
          isRequired
          isInvalid={!!bedsErrorCode}
        >
          <FormLabel>{t("Used spots")}</FormLabel>
          <NumberInput
            onFocus={handleOnFocusBedInput}
            onBlur={handleOnBlurBedInput}
            max={maxBeds - curHazardousBeds}
            min={0}
            value={curBeds || (curBeds === 0 ? 0 : "")}
            onChange={(_valueAsString, valueAsNumber) =>
              setCurBeds(valueAsNumber)
            }
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          {bedsErrorCode === "cur-max-mismatch" ? (
            <FormErrorMessage>
              {t("Current needs to be smaller than max")}
            </FormErrorMessage>
          ) : (
            <FormHelperText>
              {t("The total number beds occupied by a regular corpse")}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          maxW={"400px"}
          isDisabled={isDisabled}
          isRequired
          isInvalid={!!bedsErrorCode}
        >
          <FormLabel>{t("Maximum spots")}</FormLabel>
          <NumberInput
            onFocus={handleOnFocusBedInput}
            onBlur={handleOnBlurBedInput}
            min={1}
            max={9999999}
            value={maxBeds || (maxBeds === 0 ? 0 : "")}
            onChange={(_valueAsString, valueAsNumber) =>
              setMaxBeds(valueAsNumber)
            }
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          {bedsErrorCode === "cur-max-mismatch" ? (
            <FormErrorMessage>
              {t("Max needs to be larger than current")}
            </FormErrorMessage>
          ) : (
            <FormHelperText>
              {t("The total number of beds in this morgue")}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box display="flex" flexDirection={["column", null, null, "row"]} mt={6}>
        <FormControl
          mr={{ sm: 0, lg: 4 }}
          mb={{ sm: 4, lg: 0 }}
          maxW={"400px"}
          isDisabled={isDisabled}
          isRequired
          isInvalid={!!bedsErrorCode}
        >
          <FormLabel>{t("Used hazardous spots")}</FormLabel>
          <NumberInput
            onFocus={handleOnFocusBedInput}
            onBlur={handleOnBlurBedInput}
            max={maxBeds - curBeds}
            min={0}
            value={curHazardousBeds || (curHazardousBeds === 0 ? 0 : "")}
            onChange={(_valueAsString, valueAsNumber) =>
              setCurHazardousBeds(valueAsNumber)
            }
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          {bedsErrorCode === "cur-max-mismatch" ? (
            <FormErrorMessage>
              {t("Current needs to be smaller than max")}
            </FormErrorMessage>
          ) : (
            <FormHelperText>
              {t("The total number beds occupied by a hazardous corpse")}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Button
        isDisabled={
          isDisabled || hasNoChanges || morgueHasNotAName || !!bedsErrorCode
        }
        onClick={() =>
          onSave({
            fiName,
            svName,
            enName,
            curBeds,
            maxBeds,
            hazardousCurrent: curHazardousBeds,
          })
        }
        form={morgueFormId}
        mt={6}
        isLoading={isSaving}
      >
        {t("Save changes")}
      </Button>
    </form>
  );
};

export default MorgueForm;
