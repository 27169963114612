import { InfoIcon } from "@chakra-ui/icons";
import { useDisclosure, Button, Collapse, Box } from "@chakra-ui/react";

interface Props {
  text: string;
  url: string;
}

const CollapsibleInstructions = ({ text, url }: Props) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Button
        leftIcon={<InfoIcon />}
        colorScheme="teal"
        variant="outline"
        onClick={onToggle}
        mt="4"
      >
        {text}
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Box color="white" mt="4" rounded="md" shadow="md">
          <iframe
            title={text}
            src={url}
            width="580"
            height="580"
            allowFullScreen
            frameBorder="0"
          ></iframe>
        </Box>
      </Collapse>
    </>
  );
};

export default CollapsibleInstructions;
