import { doc, setDoc } from "firebase/firestore";
import { db } from "../config/firebase";

const updateUserNames = ({
  firstName,
  lastName,
  uid,
}: {
  firstName: string;
  lastName: string;
  uid: string;
}) => {
  return setDoc(
    doc(db, "users", uid),
    {
      userBaseInfo: {
        firstName,
        lastName,
      },
    },
    { merge: true }
  );
};

export default updateUserNames;
