import { HamburgerIcon, EditIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  Icon,
  IconButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { MdLogout } from "react-icons/md";
import LogOutDialog from "./LogOutDialog";
import ProfileDialog from "./ProfileDialog";

const SettingsMenu = () => {
  const { t } = useTranslation();
  const {
    isOpen: isLogoutDialogOpen,
    onOpen: onOpenLogoutDialog,
    onClose: onCloseLogoutDialog,
  } = useDisclosure();
  const {
    isOpen: isProfileDialogOpen,
    onOpen: onOpenProfileDialog,
    onClose: onCloseProfileDialog,
  } = useDisclosure();

  return (
    <>
      <Menu>
        <MenuButton
          ml={4}
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
        />
        <MenuList>
          <MenuItem
            onClick={onOpenProfileDialog}
            icon={<EditIcon w={4} h={4} />}
          >
            {t("Edit profile")}
          </MenuItem>
          <MenuItem
            icon={<Icon w={4} h={4} as={MdLogout} />}
            onClick={onOpenLogoutDialog}
          >
            {t("Log out")}
          </MenuItem>
        </MenuList>
      </Menu>
      <ProfileDialog
        isOpen={isProfileDialogOpen}
        onClose={onCloseProfileDialog}
      />
      <LogOutDialog isOpen={isLogoutDialogOpen} onClose={onCloseLogoutDialog} />
    </>
  );
};

export default SettingsMenu;
