import { Heading, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface Props {
  text?: string;
}

const PageNotFound = ({ text }: Props) => {
  const { t } = useTranslation();

  return (
    <VStack py={10}>
      <Heading>{text !== undefined ? text : t("Page not found")}</Heading>
    </VStack>
  );
};

export default PageNotFound;
