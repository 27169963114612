import { useTranslation } from "react-i18next";
import useLocalizedMorgueName from "../hooks/useLocalizedMorgueName";
import useMorgueData from "../hooks/useMorgueData";

interface Props {
  specificLogData: MorgueSaveDataUpdateLog;
}

const MorgueSaveDataUpdateLogTableItem = ({ specificLogData }: Props) => {
  const { t } = useTranslation();
  const { newSaveData, prevSaveData, morgueId } = specificLogData;
  const morgue = useMorgueData(morgueId);
  const morgueName = useLocalizedMorgueName(morgue?.name);
  const hasUpdatedFiName = newSaveData.fiName !== prevSaveData.fiName;
  const hasUpdatedSvName = newSaveData.svName !== prevSaveData.svName;
  const hasUpdatedEnName = newSaveData.enName !== prevSaveData.enName;
  const hasUpdatedMaxBeds = newSaveData.maxBeds !== prevSaveData.maxBeds;
  const hasUpdatedCurBeds = newSaveData.curBeds !== prevSaveData.curBeds;
  const hasUpdatedHazardousCurBeds =
    newSaveData.hazardousCurrent !== prevSaveData.hazardousCurrent;

  return (
    <>
      {`${t("Updated morgue", { morgueName })}\n${
        hasUpdatedFiName
          ? `FI: ${prevSaveData.fiName} -> ${newSaveData.fiName}\n`
          : ""
      }${
        hasUpdatedSvName
          ? `SV: ${prevSaveData.svName} -> ${newSaveData.svName}\n`
          : ""
      }${
        hasUpdatedEnName
          ? `EN: ${prevSaveData.enName} -> ${newSaveData.enName}\n`
          : ""
      }${
        hasUpdatedMaxBeds
          ? `${t("Maximum spots")}: ${prevSaveData.maxBeds} -> ${
              newSaveData.maxBeds
            }\n`
          : ""
      }${
        hasUpdatedCurBeds
          ? `${t("Used spots")}: ${prevSaveData.curBeds} -> ${
              newSaveData.curBeds
            }\n`
          : ""
      }${
        hasUpdatedHazardousCurBeds
          ? `${t("Used hazardous spots")}: ${
              prevSaveData.hazardousCurrent
            } -> ${newSaveData.hazardousCurrent}\n`
          : ""
      }`}
    </>
  );
};

export default MorgueSaveDataUpdateLogTableItem;
