import React, { useContext } from "react";

const defaultUser: User = {
  uid: "uid",
  roles: {},
  userBaseInfo: {
    firstName: "firstName",
    lastName: "lastName",
    email: "example@email.com",
  },
  language: "fi",
};

const AuthenticatedUserContext = React.createContext<User>(defaultUser);

interface Props {
  user: User;
  children?: React.ReactNode;
}

const AuthenticatedUserContextProvider = ({ children, user }: Props) => {
  return (
    <AuthenticatedUserContext.Provider value={user}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};

export const useAuthenticatedUser = () => {
  const authenticatedUser = useContext(AuthenticatedUserContext);
  return authenticatedUser;
};

export default AuthenticatedUserContextProvider;
