import { Box, Heading, Text, Button, VStack, HStack } from "@chakra-ui/react";
import { CloseIcon, AddIcon } from "@chakra-ui/icons";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import DeleteWelfareAreaUserDialog from "../components/DeleteWelfareAreaUserDialog";
import addMaintainerToWelfareArea from "../firestore/addMaintainerToWelfareArea";
import removeMaintainerFromWelfareArea from "../firestore/removeMaintainerFromWelfareArea";
import { useAuthenticatedUser } from "../contexts/AuthenticatedUserContext";

interface Props {
  canEditMaintainers: boolean;
  canEditUsers: boolean;
  isUserMaintainer: boolean;
  user: WelfareUser;
  welfareAreaId: string;
}

const UserListItem = ({
  user,
  welfareAreaId,
  canEditMaintainers,
  isUserMaintainer,
  canEditUsers,
}: Props) => {
  const { t } = useTranslation();
  const currentUser = useAuthenticatedUser();
  const { email, firstName, lastName, uid } = user;

  const handleToggleMaintainer = async () => {
    const createdBy: UserLogData = {
      email: currentUser.userBaseInfo.email,
      firstName: currentUser.userBaseInfo.firstName,
      lastName: currentUser.userBaseInfo.lastName,
      uid: currentUser.uid,
    };
    const specificLogData: WelfareAreaMaintainerUpdateLog = {
      type: "welfareAreaMaintainerUpdateLog",
      updatedUser: {
        email,
        firstName,
        lastName,
        uid,
      },
      isUpdatedToMaintainer: !isUserMaintainer,
      welfareAreaId,
    };
    try {
      if (isUserMaintainer) {
        await removeMaintainerFromWelfareArea({
          createdBy,
          specificLogData,
        });
      } else {
        await addMaintainerToWelfareArea({
          createdBy,
          specificLogData,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error({ error });
    }
  };

  return (
    <VStack alignItems="flex-start" spacing={1} pt={1} pb={1}>
      <Box>
        <Heading variant="h4" size="md">
          {email}
        </Heading>
        {firstName && lastName ? (
          <Text>{`${firstName} ${lastName}`}</Text>
        ) : null}
      </Box>
      <HStack spacing={1} alignItems="center">
        <Button
          disabled={!canEditMaintainers}
          size="sm"
          colorScheme="green"
          variant={isUserMaintainer ? "solid" : "outline"}
          onClick={handleToggleMaintainer}
          rightIcon={isUserMaintainer ? <CloseIcon /> : <AddIcon />}
        >
          {t("Maintainer")}
        </Button>
        <DeleteWelfareAreaUserDialog
          disabled={isUserMaintainer || !canEditUsers}
          user={user}
          welfareAreaId={welfareAreaId}
        />
      </HStack>
    </VStack>
  );
};

export default UserListItem;
