import { Container, Box } from "@chakra-ui/react";

interface Props {
  centerContent?: boolean;
  children?: React.ReactNode;
}

const RouteContainer = ({ children, centerContent }: Props) => {
  return (
    <Box height={"100%"} overflow="scroll">
      <Container
        height={"100%"}
        maxW="container.xl"
        centerContent={centerContent}
      >
        {children}
      </Container>
    </Box>
  );
};
//  <Box h="3000px">
export default RouteContainer;
