import { ChevronDownIcon } from "@chakra-ui/icons";
import { Menu, MenuButton, Button, MenuList, MenuItem } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { withTranslation, WithTranslationProps } from "react-i18next";
import { useUserContext } from "../../contexts/UserContext";
import updateUserLanguage from "../../firestore/updateUserLanguage";

const LanguageMenu = ({ i18n }: WithTranslationProps) => {
  const user = useUserContext();
  const uid = user?.uid;
  const currentLanguage = i18n?.language || "fi";
  const currentLanguageString =
    currentLanguage === "fi"
      ? "Suomi"
      : currentLanguage === "sv"
      ? "Svenska"
      : "English";

  const handleChangeLanguage = async (language: Language) => {
    i18n?.changeLanguage(language); // This is most likely not needed, but left it here in case there is some delay with updating the document.
    if (uid) {
      try {
        await updateUserLanguage({ language, uid });
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      }
    }
  };

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
        {currentLanguageString}
      </MenuButton>
      <MenuList>
        <MenuItem onClick={() => handleChangeLanguage("fi")}>Suomi</MenuItem>
        <MenuItem onClick={() => handleChangeLanguage("sv")}>Svenska</MenuItem>
        <MenuItem onClick={() => handleChangeLanguage("en")}>English</MenuItem>
      </MenuList>
    </Menu>
  );
};

export default withTranslation()(LanguageMenu);
