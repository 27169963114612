import { useCallback, useEffect, useState } from "react";
import { User as FirebaseUser, onIdTokenChanged } from "firebase/auth";
import { onSnapshot, doc } from "firebase/firestore";
import { auth, db } from "../config/firebase";
import i18n, { resources } from "../translations/i18n";

type FirebaseUserState = FirebaseUser | undefined | null;
type UserDataState = UserDocument | null | undefined;
export type UserState = User | null | undefined;

const useUserData = () => {
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUserState>();
  const [userData, setUserData] = useState<UserDataState>();
  const [user, setUser] = useState<UserState>();

  const fetchAndSetCustomClaims = useCallback(
    async (firebaseUser: FirebaseUser, userData: UserDocument) => {
      //const { claims } = await firebaseUser.getIdTokenResult();

      const roles: UserRoles = {};

      setUser({ ...userData, roles });
    },
    []
  );

  useEffect(() => {
    if (firebaseUser && userData) {
      fetchAndSetCustomClaims(firebaseUser, userData);
    } else if (firebaseUser === undefined || userData === undefined) {
      setUser(undefined);
    } else {
      setUser(null);
    }
  }, [firebaseUser, userData, fetchAndSetCustomClaims]);

  useEffect(() => {
    let unsubscribe;

    if (firebaseUser) {
      return onSnapshot(doc(db, "users", firebaseUser.uid), (userDoc) => {
        if (userDoc && userDoc.exists()) {
          const data = userDoc.data({
            serverTimestamps: "estimate",
          }) as UserDocument;
          setUserData(data);
        } else {
          setUserData(null);
        }
      });
    } else {
      setUser(firebaseUser);
    }

    return unsubscribe;
  }, [firebaseUser]);

  useEffect(() => {
    const unsubscribe = onIdTokenChanged(auth, (user) => {
      if (user) {
        setFirebaseUser(user);
      } else {
        setFirebaseUser(null);
      }
    });

    return unsubscribe;
  }, []);

  const userLanguage = user?.language;

  useEffect(() => {
    if (userLanguage && resources[userLanguage] !== undefined) {
      i18n.changeLanguage(userLanguage);
    }
  }, [userLanguage]);

  return user;
};

export default useUserData;
