import {
  doc,
  arrayUnion,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { v4 } from "uuid";

const addMaintainerToWelfareArea = ({
  createdBy,
  specificLogData,
}: {
  createdBy: UserLogData;
  specificLogData: WelfareAreaMaintainerUpdateLog;
}) => {
  const logId = v4();
  const log: Log = {
    id: logId,
    specificLogData,
    createdAt: serverTimestamp() as Timestamp,
    createdBy,
  };

  const batch = writeBatch(db);

  const logRef = doc(db, "logs", logId);
  batch.set(logRef, log);

  const welfareAreaDocRef = doc(
    db,
    "welfareAreas",
    specificLogData.welfareAreaId
  );
  batch.update(welfareAreaDocRef, {
    maintainers: arrayUnion(specificLogData.updatedUser.uid),
  });

  return batch.commit();
};

export default addMaintainerToWelfareArea;
