import { useContext, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import WelfareAreasDataContext from "../contexts/WelfareAreasDataContext";
import useIsAreaMaintainer from "../hooks/useIsAreaMaintainer";

const useAuthenticatedRedirect = () => {
  const { pathname } = useLocation();
  const welfareAreasData = useContext(WelfareAreasDataContext);
  const navigate = useNavigate();

  const handleNavigateToUrl = useCallback(
    (url: string, replace = false) => {
      navigate(url, { replace });
    },
    [navigate]
  );

  const firstWelfareAreaId = useMemo(
    () => welfareAreasData.welfareAreas[0]?.id,
    [welfareAreasData.welfareAreas]
  );
  const isWelfareAreaMaintainer = useIsAreaMaintainer(firstWelfareAreaId || "");

  useEffect(() => {
    if (firstWelfareAreaId !== undefined) {
      const requiredPathname = `/areas/${firstWelfareAreaId}${
        isWelfareAreaMaintainer ? "" : "/morgues"
      }`;
      const shouldRedirect = !pathname.startsWith(requiredPathname);
      if (shouldRedirect) {
        handleNavigateToUrl(requiredPathname);
      }
    }
  }, [
    handleNavigateToUrl,
    firstWelfareAreaId,
    isWelfareAreaMaintainer,
    pathname,
  ]);
};

export default useAuthenticatedRedirect;
