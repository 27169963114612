import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import React from "react";
import { useTranslation } from "react-i18next";
import { auth } from "../../config/firebase";

interface Props {
  onClose(): void;
  isOpen: boolean;
}

const LogOutDialog = ({ onClose, isOpen }: Props) => {
  const { t } = useTranslation();
  const cancelRef = React.useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("Log out")}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t("Are you sure that you wish to log out?")}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                signOut(auth);
                onClose();
              }}
              ml={3}
            >
              {t("Log out")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default LogOutDialog;
