import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import { useAuthenticatedUser } from "../contexts/AuthenticatedUserContext";
import deleteWelfareAreaUser from "../firestore/deleteWelfareAreaUser";

interface Props {
  user: WelfareUser;
  welfareAreaId: string;
  disabled: boolean;
}

const DeleteWelfareAreaUserDialog = ({
  user,
  welfareAreaId,
  disabled,
}: Props) => {
  const currentUser = useAuthenticatedUser();
  const { email, uid, firstName, lastName } = user;
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef(null);

  const handleDeleteClick = async () => {
    try {
      await deleteWelfareAreaUser({
        createdBy: {
          uid: currentUser.uid,
          email: currentUser.userBaseInfo.email,
          lastName: currentUser.userBaseInfo.lastName,
          firstName: currentUser.userBaseInfo.firstName,
        },
        specificLogData: {
          isAddedToWelfareArea: false,
          type: "welfareAreaUserUpdateLog",
          welfareAreaId,
          updatedUser: {
            email,
            uid,
            firstName,
            lastName,
          },
        },
      });
      onClose();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <>
      <Button
        disabled={disabled}
        size="sm"
        colorScheme="red"
        variant="outline"
        onClick={onOpen}
      >
        {t("Delete")}
      </Button>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("Delete user", { email })}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t(
                "This user will no longer be able to see data in this welfare area"
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {t("Cancel")}
              </Button>
              <Button onClick={handleDeleteClick} ml={3}>
                {t("Delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default DeleteWelfareAreaUserDialog;
