import { doc, serverTimestamp, writeBatch } from "firebase/firestore";
import { db } from "../config/firebase";
import { v4 } from "uuid";

const updateMorgueData = ({
  createdBy,
  specificLogData,
}: {
  createdBy: Log["createdBy"];
  specificLogData: MorgueSaveDataUpdateLog;
}) => {
  const logId = v4();
  const log: Log = {
    id: logId,
    specificLogData,
    createdAt: serverTimestamp() as Timestamp,
    createdBy,
  };

  const batch = writeBatch(db);

  const logRef = doc(db, "logs", logId);
  batch.set(logRef, log);

  const { newSaveData, prevSaveData, morgueId } = specificLogData;
  const name: Morgue["name"] = {
    fi: newSaveData.fiName,
    sv: newSaveData.svName,
    en: newSaveData.enName,
  };

  const hasUpdatedBeds =
    newSaveData.curBeds !== prevSaveData.curBeds ||
    newSaveData.maxBeds !== prevSaveData.maxBeds ||
    newSaveData.hazardousCurrent !== prevSaveData.hazardousCurrent;

  const beds: Morgue["beds"] = {
    current: newSaveData.curBeds,
    max: newSaveData.maxBeds,
    hazardousCurrent: newSaveData.hazardousCurrent,
    lastUpdated: serverTimestamp() as Timestamp,
  };

  const morgueDocRef = doc(db, "morgues", morgueId);

  hasUpdatedBeds
    ? batch.update(morgueDocRef, {
        lastUpdated: serverTimestamp(),
        name,
        beds,
      })
    : batch.update(morgueDocRef, {
        lastUpdated: serverTimestamp(),
        name,
      });

  return batch.commit();
};

export default updateMorgueData;
