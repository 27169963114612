import React, { useContext } from "react";
import useUserData, { UserState } from "../hooks/useUserData";

const UserContext = React.createContext<UserState>(undefined);

interface Props {
  children?: React.ReactNode;
}

const UserContextProvider = ({ children }: Props) => {
  const userData = useUserData();

  return (
    <UserContext.Provider value={userData}>{children}</UserContext.Provider>
  );
};

export const useUserContext = () => {
  const userData = useContext(UserContext);
  return userData;
};

export default UserContextProvider;
