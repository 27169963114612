import React, { createContext, useMemo } from "react";
import LoadingSkeleton from "../components/LoadingSkeleton";
import useMorgues from "../hooks/useMorgues";
import useUserWelfareAreas from "../hooks/useUserWelfareAreas";

interface WelfareAreasDataContextValue {
  welfareAreas: WelfareArea[];
  morgues: Morgue[];
}

const WelfareAreasDataContext = createContext<WelfareAreasDataContextValue>({
  welfareAreas: [],
  morgues: [],
});

interface Props {
  children: React.ReactNode;
}

export const WelfareAreasDataContextProvider = ({ children }: Props) => {
  const welfareAreas = useUserWelfareAreas();

  const welfareAreaIds = useMemo(
    () => (welfareAreas || []).map((welfareArea) => welfareArea.id),
    [welfareAreas]
  );

  const morgues = useMorgues(welfareAreaIds);

  const data: WelfareAreasDataContextValue | null | undefined = useMemo(() => {
    if (welfareAreas === undefined || morgues === undefined) {
      return undefined;
    }
    if (welfareAreas === null || morgues === null) {
      return null;
    }
    return { welfareAreas, morgues };
  }, [welfareAreas, morgues]);

  return data === null ? null : data === undefined ? (
    <LoadingSkeleton />
  ) : (
    <WelfareAreasDataContext.Provider value={data}>
      {children}
    </WelfareAreasDataContext.Provider>
  );
};

export default WelfareAreasDataContext;
