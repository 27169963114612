import { MinusIcon, AddIcon } from "@chakra-ui/icons";
import { IconButton, Box, Text } from "@chakra-ui/react";

interface MorgueUpdateBedsControlsProps {
  isDecrementDisabled?: boolean;
  decrementIconAriaLabel: string;
  onDecrementIconClick(): void;
  currentBeds: number;
  maxBeds?: number;
  isIncrementDisabled?: boolean;
  incrementIconAriaLabel: string;
  onIncrementIconClick(): void;
  headingText: string;
}

const MorgueUpdateBedsControls = ({
  isDecrementDisabled,
  decrementIconAriaLabel,
  onDecrementIconClick,
  currentBeds,
  maxBeds,
  isIncrementDisabled,
  incrementIconAriaLabel,
  onIncrementIconClick,
  headingText,
}: MorgueUpdateBedsControlsProps) => {
  return (
    <Box alignItems="center" display="flex" flexDirection="column">
      <Text fontWeight={"bold"}>{headingText}</Text>
      <Box
        alignSelf={"center"}
        alignItems="center"
        display="flex"
        flexDirection="row"
        mb={4}
        mx={2}
      >
        <IconButton
          disabled={isDecrementDisabled}
          icon={<MinusIcon />}
          aria-label={decrementIconAriaLabel}
          onClick={onDecrementIconClick}
        />
        <Text mx={4}>
          {!!maxBeds ? `${currentBeds}/${maxBeds}` : currentBeds}
        </Text>
        <IconButton
          disabled={isIncrementDisabled}
          icon={<AddIcon />}
          aria-label={incrementIconAriaLabel}
          onClick={onIncrementIconClick}
        />
      </Box>
    </Box>
  );
};

export default MorgueUpdateBedsControls;
