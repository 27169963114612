import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box,
  FormControl,
  FormLabel,
  Input,
  Text,
} from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { auth } from "../../config/firebase";
import { useAuthenticatedUser } from "../../contexts/AuthenticatedUserContext";
import updateUserNames from "../../firestore/updateUserNames";

interface Props {
  onClose(): void;
  isOpen: boolean;
}

const profileDialogId = "profileDialogId";

const ProfileDialog = ({ onClose, isOpen }: Props) => {
  const authenticatedUser = useAuthenticatedUser();
  const { userBaseInfo, uid } = authenticatedUser;
  const { firstName: savedFirstName, lastName: savedLastName } = userBaseInfo;
  const [firstName, setFirstName] = useState(savedFirstName || "");
  const [lastName, setLastName] = useState(savedLastName || "");
  const { t } = useTranslation();
  const cancelRef = React.useRef(null);
  const hasProvidedBaseInfo = savedFirstName !== null && savedLastName !== null;
  const isFirstNameEmpty = firstName.length === 0;
  const isLastNameEmpty = lastName.length === 0;
  const hasNotEditedFirstName =
    savedFirstName !== null && savedFirstName === firstName;
  const hasNotEditedLastName =
    savedLastName !== null && savedLastName === lastName;
  const hasEditedNeitherName = hasNotEditedFirstName && hasNotEditedLastName;
  const shouldDisableSubmit =
    isFirstNameEmpty || isLastNameEmpty || hasEditedNeitherName;

  useEffect(() => {
    if (isOpen) {
      setFirstName(savedFirstName || "");
      setLastName(savedLastName || "");
    }
  }, [savedFirstName, savedLastName, isOpen]);

  return (
    <AlertDialog
      closeOnOverlayClick={hasProvidedBaseInfo}
      closeOnEsc={hasProvidedBaseInfo}
      isOpen={isOpen || !hasProvidedBaseInfo}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      size="lg"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t("Edit profile")}
          </AlertDialogHeader>

          <AlertDialogBody>
            {!hasProvidedBaseInfo ? (
              <Text mb={4}>
                {t(
                  "Please provide some basic information, so that your colleagues can identify you more easily in the system."
                )}
              </Text>
            ) : null}
            <form id={profileDialogId}>
              <Box display="flex" flexDirection={"column"}>
                <FormControl flex={1} isRequired mb={4}>
                  <FormLabel>{t("First name")}</FormLabel>
                  <Input
                    placeholder={t("Input your first name...")}
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                  />
                </FormControl>
                <FormControl flex={1} isRequired>
                  <FormLabel>{t("Last name")}</FormLabel>
                  <Input
                    placeholder={t("Input your last name...")}
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </FormControl>
              </Box>
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            {!hasProvidedBaseInfo ? null : (
              <Button ref={cancelRef} onClick={onClose}>
                {
                  t(
                    "Cancel"
                  ) /* This button is not rendered if the user has not provided base info. */
                }
              </Button>
            )}
            <Button
              onClick={async () => {
                await updateUserNames({
                  firstName,
                  lastName,
                  uid,
                });
                onClose();
              }}
              ml={3}
              disabled={shouldDisableSubmit}
            >
              {t("Save changes")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ProfileDialog;
