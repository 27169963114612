import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  startAt,
  QueryDocumentSnapshot,
  DocumentData,
  limit,
} from "firebase/firestore";
import { useCallback, useState } from "react";
import { db } from "../config/firebase";

const docsToLoad = 20;

const useMorgueLogs = (morgueId: string, welfareAreaId: string) => {
  const [canFetchMore, setCanFetchMore] = useState(false);
  const [logs, setLogs] = useState<Log[]>([]);
  const [startFetchAt, setStartFetchAt] =
    useState<QueryDocumentSnapshot<DocumentData>>();

  const fetch = useCallback(async () => {
    setCanFetchMore(false);
    const c = collection(db, "logs");
    const o = orderBy("createdAt", "desc");
    const w = where("specificLogData.morgueId", "==", morgueId);
    const w2 = where("specificLogData.welfareAreaId", "==", welfareAreaId);
    const l = limit(docsToLoad + 1);

    const q = startFetchAt
      ? query(c, o, w, w2, l, startAt(startFetchAt))
      : query(c, o, w, w2, l);

    const docs = (await getDocs(q)).docs;

    if (docs.length > 0) {
      setStartFetchAt(docs[docs.length - 1]);
      const slicedDocs =
        docs.length === docsToLoad + 1 ? docs.slice(0, -1) : docs;
      const docsData = slicedDocs.map(
        (docSnapshot) => docSnapshot.data() as Log
      );
      setLogs(logs.concat(docsData));
    }

    if (docs.length === docsToLoad + 1) {
      setCanFetchMore(true);
    }
  }, [logs, startFetchAt, morgueId, welfareAreaId]);

  return { logs, fetch, canFetchMore };
};

export default useMorgueLogs;
