import { updateDoc, doc } from "firebase/firestore";
import { db } from "../config/firebase";

const updateUserLanguage = ({
  language,
  uid,
}: {
  language: Language;
  uid: string;
}) => {
  return updateDoc(doc(db, "users", uid), {
    language,
  });
};

export default updateUserLanguage;
