import { useUserContext } from "../contexts/UserContext";
import useMorgueData from "./useMorgueData";

const useIsMorgueUser = (morgueId: string) => {
  const userData = useUserContext();
  const morgueData = useMorgueData(morgueId);
  if (userData === null || userData === undefined || morgueData === undefined) {
    return false;
  }

  const { uid } = userData;
  const { users } = morgueData;
  return users.includes(uid);
};

export default useIsMorgueUser;
