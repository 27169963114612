import { BrowserRouter } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import RootRouter from "./routes/RootRouter";
import "./translations/i18n";
import { useUserContext } from "./contexts/UserContext";

function App() {
  const user = useUserContext();
  return (
    <BrowserRouter>
      <Box
        display="flex"
        flexDirection={"column"}
        maxHeight="100vh"
        flex={1}
        height="100vh"
        width="100%"
      >
        <RootRouter />
      </Box>
    </BrowserRouter>
  );
}

export default App;

/*
/
/areas
/areas/vaasa
/areas/vaasa/morgues
/areas/vaasa/morgues/darra
/areas/vaasa/users
/areas/vaasa/users/rasseP

/admin
/admin/vaasa
*/
