const isDevelopment = process.env.NODE_ENV === "development";

const scribeHowLinks = {
  addUser: {
    sv: "https://scribehow.com/embed/Workflow__vseQPtJ-RBmt-S174ulC4A",
    en: "https://scribehow.com/embed/Add_users__2uqx0qbaQ4ut6s5lMsOxFQ",
    fi: "https://scribehow.com/embed/Lisaa_kayttajia__0ZmU9CakSpKxQu5UwSm-zA",
  },
  editMorgueAdmin: {
    sv: "https://scribehow.com/embed/Redigera_ett_likforvaringsstalle__dnYt4oWfQheKYEXA8fiqQw",
    en: "https://scribehow.com/embed/Edit_a_morgue__sGUcPrqGR-uPIz6JUmm2-A",
    fi: "https://scribehow.com/embed/Muokkaa_ruumishuonetta__SAFqO4IXQ5i0rXGsBpeFxw",
  },
};

export { isDevelopment, scribeHowLinks };
