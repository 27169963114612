import {
  Button,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Tag,
  TagCloseButton,
  TagLabel,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import addWelfareAreaUsers from "../callableFunctions/addWelfareAreaUsers";

interface Props {
  welfareAreaId: string;
}

const CreateWelfareAreaUsersForm = ({ welfareAreaId }: Props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [isAdding, setIsAdding] = useState(false);

  const handleAddUsers = async () => {
    setIsAdding(true);
    try {
      await addWelfareAreaUsers({ emails, welfareAreaId });
      setEmails([]);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
    setIsAdding(false);
  };

  const handleAddSelectedEmail = () => {
    setEmails(emails.concat(inputValue));
    setInputValue("");
  };

  const handleDeselectEmail = (emailToDeselect: string) => {
    setEmails(emails.filter((email) => email !== emailToDeselect));
  };

  return (
    <VStack my={10} alignItems="flex-start" spacing={2}>
      <Heading variant="h4" size="md">
        {t("Add new users to this welfare area")}
      </Heading>
      <InputGroup size="md">
        <Input
          value={inputValue}
          onChange={({ target }) => setInputValue(target.value)}
          pr="5.5rem"
          placeholder={t("Email")}
        />
        <InputRightElement mr="0.5rem" width="4.5rem">
          <Button
            disabled={inputValue.length === 0 || emails.includes(inputValue)}
            h="1.75rem"
            size="sm"
            onClick={handleAddSelectedEmail}
          >
            {t("Add")}
          </Button>
        </InputRightElement>
      </InputGroup>
      <Wrap spacing={2}>
        {emails.map((email) => (
          <WrapItem key={`user-to-add-key-${email}`}>
            <Tag borderRadius="full">
              <TagLabel>{email}</TagLabel>
              <TagCloseButton onClick={() => handleDeselectEmail(email)} />
            </Tag>
          </WrapItem>
        ))}
      </Wrap>
      <Button
        disabled={emails.length === 0 || isAdding}
        isLoading={isAdding}
        onClick={handleAddUsers}
        colorScheme="orange"
      >
        {t("Add users")}
      </Button>
    </VStack>
  );
};

export default CreateWelfareAreaUsersForm;
