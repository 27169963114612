import { Routes, Route } from "react-router-dom";
import AreaLogsPage from "../areas/AreaLogsPage";
import AreaPage from "../areas/AreaPage";
import AreasPage from "../areas/AreasPage";
import MorgueLogsPage from "../areas/MorgueLogsPage";
import MorguePage from "../areas/MorguePage";
import MorguesPage from "../areas/MorguesPage";
import useAuthenticatedRedirect from "../hooks/useAuthenticatedRedirect";
import RouteContainer from "./RouteContainer";

const AuthenticatedRouter = () => {
  useAuthenticatedRedirect();

  return (
    <Routes>
      <Route path="areas">
        <Route
          path=""
          element={
            <RouteContainer>
              <AreasPage />
            </RouteContainer>
          }
        />
        <Route path=":area">
          <Route
            path=""
            element={
              <RouteContainer>
                <AreaPage />
              </RouteContainer>
            }
          />
          <Route
            path="logs"
            element={
              <RouteContainer>
                <AreaLogsPage />
              </RouteContainer>
            }
          />
          <Route path="morgues">
            <Route
              path=""
              element={
                <RouteContainer>
                  <MorguesPage />
                </RouteContainer>
              }
            />
            <Route path=":morgue">
              <Route
                path=""
                element={
                  <RouteContainer>
                    <MorguePage />
                  </RouteContainer>
                }
              />
              <Route
                path="logs"
                element={
                  <RouteContainer>
                    <MorgueLogsPage />
                  </RouteContainer>
                }
              />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={null} />
    </Routes>
  );
};

export default AuthenticatedRouter;
