import { useTranslation } from "react-i18next";
import userNameString from "../utils/userNameString";

interface Props {
  specificLogData: WelfareAreaMaintainerUpdateLog;
}

const WelfareAreaMaintainerUpdateLogTableItem = ({
  specificLogData,
}: Props) => {
  const { t } = useTranslation();
  const { isUpdatedToMaintainer, updatedUser } = specificLogData;
  const eventText = t(
    isUpdatedToMaintainer ? "Updated to maintainer" : "Removed as maintainer",
    { name: userNameString(updatedUser) }
  );

  return <>{eventText}</>;
};

export default WelfareAreaMaintainerUpdateLogTableItem;
