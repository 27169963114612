import { VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useWelfareAreaLogs from "../hooks/useWelfareAreaLogs";
import LogsTable from "../LogsTable";

const AreaLogsPage = () => {
  const { t } = useTranslation();
  const { area } = useParams();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { logs, fetch, canFetchMore } = useWelfareAreaLogs(area || "");

  useEffect(() => {
    if (isFirstRender) {
      fetch();
      setIsFirstRender(false);
    }
  }, [fetch, isFirstRender]);

  return (
    <VStack py={10} spacing={8} alignItems="flex-start">
      {logs !== undefined && logs !== null ? (
        <LogsTable
          footerButton={{
            text: t("Show more"),
            disabled: !canFetchMore,
            onClick: fetch,
          }}
          logs={logs}
        />
      ) : null}
    </VStack>
  );
};

export default AreaLogsPage;
