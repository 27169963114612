import { useAuthenticatedUser } from "../contexts/AuthenticatedUserContext";

export const getLocalizedName = (name: Morgue["name"], language: Language) => {
  if (name[language].length > 0) {
    return name[language];
  }

  const { fi, sv, en } = name;

  return fi.length > 0 ? fi : sv.length > 0 ? sv : en;
};

// allow undefined to simplify usage.
const useLocalizedMorgueName = (name?: Morgue["name"]) => {
  const { language } = useAuthenticatedUser();

  if (!name) {
    return "";
  }

  return getLocalizedName(name, language);
};

export default useLocalizedMorgueName;
