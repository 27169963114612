import { useContext } from "react";
import { useUserContext } from "../contexts/UserContext";
import WelfareAreasDataContext from "../contexts/WelfareAreasDataContext";

const useIsAreaAdmin = (area: string) => {
  const user = useUserContext();
  const welfareAreaData = useContext(WelfareAreasDataContext);
  return !!welfareAreaData.welfareAreas.find(
    (welfareArea) => welfareArea.id === area && welfareArea.admin === user?.uid
  );
};

export default useIsAreaAdmin;
