import { Routes, Route } from "react-router-dom";
import Login from "../components/Login";
import FinishLogin from "../components/FinishLogin";
import RouteContainer from "./RouteContainer";
import { useUserContext } from "../contexts/UserContext";
import { WelfareAreasDataContextProvider } from "../contexts/WelfareAreasDataContext";
import useUnauthenticatedRedirect from "../hooks/useUnauthenticatedRedirect";
import AuthenticatedRouter from "./AuthenticatedRouter";
import Header from "../components/header/Header";
import AuthenticatedUserContextProvider from "../contexts/AuthenticatedUserContext";

const RootRouter = () => {
  const user = useUserContext();
  useUnauthenticatedRedirect();

  return user === undefined ? null : user === null ? (
    <>
      <Header />
      <Routes>
        <Route
          path="login"
          element={
            <RouteContainer>
              <Login />
            </RouteContainer>
          }
        />
        <Route
          path="finishlogin"
          element={
            <RouteContainer>
              <FinishLogin />
            </RouteContainer>
          }
        />
        <Route path="*" element={null} />
      </Routes>
    </>
  ) : (
    <AuthenticatedUserContextProvider user={user}>
      <WelfareAreasDataContextProvider>
        <Header />
        <AuthenticatedRouter />
      </WelfareAreasDataContextProvider>
    </AuthenticatedUserContextProvider>
  );
};

export default RootRouter;
