import { doc, serverTimestamp, runTransaction } from "firebase/firestore";
import { v4 } from "uuid";
import { db } from "../config/firebase";

const incrementCurrentMorgueBeds = ({
  specificLogData,
  createdBy,
}: {
  specificLogData: MorgueBedsUpdateLog;
  createdBy: UserLogData;
}) => {
  const logId = v4();
  const logRef = doc(db, "logs", logId);
  const morgueDocRef = doc(db, "morgues", specificLogData.morgueId);
  const transaction = runTransaction(db, async (transaction) => {
    const morgueDoc = await transaction.get(morgueDocRef);
    const morgueDocData = morgueDoc.data() as Morgue;
    const shouldUpdateBeds =
      specificLogData.newCurrentBeds !== morgueDocData.beds.current ||
      specificLogData.newHazardousCurrent !==
        morgueDocData.beds.hazardousCurrent;

    if (shouldUpdateBeds) {
      const log: Log = {
        id: logId,
        specificLogData: {
          ...specificLogData,
          previousCurrentBeds: morgueDocData.beds.current,
        },
        createdAt: serverTimestamp() as Timestamp,
        createdBy,
      };
      transaction.set(logRef, log);
      transaction.update(morgueDocRef, {
        "beds.current": specificLogData.newCurrentBeds,
        "beds.hazardousCurrent": specificLogData.newHazardousCurrent,
        "beds.lastUpdated": serverTimestamp(),
      });
    }
  });

  return transaction;
};

export default incrementCurrentMorgueBeds;
