import {
  Heading,
  Select,
  Box,
  Button,
  VStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useMorgueData from "../hooks/useMorgueData";
import useWelfareAreaUsers from "../hooks/useWelfareAreaUsers";

interface MorgueSelectUserProps {
  onAddUser(user: UserLogData): void;
  onRemoveUser(user: UserLogData): void;
  isSaving?: boolean;
  isDisabled?: boolean;
}

const MorgueSelectUser = ({
  isSaving,
  isDisabled,
  onAddUser,
  onRemoveUser,
}: MorgueSelectUserProps) => {
  const [selectedUid, setSelectedUid] = useState<string>("");
  const { area: welfareAreaId, morgue: morgueId } = useParams();
  const morgue = useMorgueData(morgueId || "");
  const { t } = useTranslation();
  const welfareAreaUsers = useWelfareAreaUsers(welfareAreaId || "");
  const welfareAreaUsersArray = Object.values(welfareAreaUsers);
  const morgueUsers = welfareAreaUsersArray.filter((user) =>
    (morgue?.users || []).includes(user.uid)
  );

  useEffect(() => {
    const morgueUsers = morgue?.users || [];
    if (morgueUsers.includes(selectedUid)) {
      setSelectedUid("");
    }
  }, [morgue?.users, selectedUid]);

  return (
    <>
      <Heading mb={2} fontSize="lg" mt={12}>
        {t("Edit users")}
      </Heading>
      <Text maxW="550px" mb={4}>
        {t(
          "You can add and remove users below. The users are able to increase or decrease the number of used spots in the morgue."
        )}
      </Text>
      <Box display="flex" flexDir={"row"} alignItems="center">
        <Select
          maxW="300px"
          mr={2}
          placeholder={t("Select user to add...")}
          value={selectedUid}
          onChange={(event) => setSelectedUid(event.target.value)}
          disabled={isDisabled}
        >
          {welfareAreaUsersArray
            .filter((user) => !(morgue?.users || []).includes(user.uid))
            .map((user) => {
              return (
                <option
                  key={`morgue-select-user-key-${user.uid}`}
                  value={user.uid}
                >
                  {user.firstName !== null && user.lastName !== null
                    ? `${user.firstName} ${user.lastName}`
                    : user.email}
                </option>
              );
            })}
        </Select>
        <Button
          disabled={selectedUid === "" || isDisabled}
          isLoading={isSaving}
          onClick={() => {
            const userToUpdate = welfareAreaUsers[selectedUid];
            if (userToUpdate) {
              onAddUser(userToUpdate);
            }
          }}
        >
          {t("Add user")}
        </Button>
      </Box>
      <VStack mt={4} alignItems={"flex-start"}>
        {morgueUsers.map((user) => {
          return (
            <Tag
              size="lg"
              key={`morgue-user-key-${user.uid}`}
              borderRadius="full"
              variant="solid"
              colorScheme="green"
            >
              <TagLabel>
                {user.firstName !== null && user.lastName !== null
                  ? `${user.firstName} ${user.lastName}`
                  : user.email}
              </TagLabel>
              <TagCloseButton
                onClick={() => onRemoveUser(user)}
                isDisabled={isDisabled}
              />
            </Tag>
          );
        })}
      </VStack>
    </>
  );
};

export default MorgueSelectUser;
