import { useTranslation } from "react-i18next";
import useLocalizedMorgueName from "../hooks/useLocalizedMorgueName";
import useMorgueData from "../hooks/useMorgueData";

interface Props {
  specificLogData: MorgueCreateLog;
}

const MorgueCreateLogTableItem = ({ specificLogData }: Props) => {
  const { t } = useTranslation();
  const { morgueId } = specificLogData;
  const morgue = useMorgueData(morgueId);
  const morgueName = useLocalizedMorgueName(morgue?.name);

  return <>{t("Created morgue", { morgueName })}</>;
};

export default MorgueCreateLogTableItem;
