const createDateString = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const correctedHour = hour < 10 ? `0${hour}` : hour;
  const minutes = date.getMinutes();
  const correcteMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const dateString = `${day}.${month}.${year} ${correctedHour}:${correcteMinutes}`;

  return dateString;
};

export default createDateString;
