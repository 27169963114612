import { SetStateAction, useEffect, useState } from "react";
import { Box, Button, Heading, Input, useToast, Text } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { signInWithEmailLink } from "firebase/auth";
import { auth } from "../config/firebase";
import { useTranslation } from "react-i18next";
import { emailForSignInKey } from "./Login";

const finishLoginToastId = "finishLoginToastId";
const errorFinishLoginToastId = "errorFinishLoginToastId";

const FinishLogin = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [hasCheckedForEmail, setHasCheckedForEmail] = useState(false);
  const [isFinishingLogin, setIsFinishingLogin] = useState(false);
  const [email, setEmail] = useState("");
  const handleChange = (event: { target: { value: SetStateAction<string> } }) =>
    setEmail(event.target.value);

  const submitForm = async () => {
    setIsFinishingLogin(true);
    try {
      await signInWithEmailLink(auth, email, window.location.href);
      window.localStorage.removeItem(emailForSignInKey);
      if (!toast.isActive(finishLoginToastId)) {
        toast({
          id: finishLoginToastId,
          title: t("Logged in!"),
          description: t("You were successfully logged in!"),
          status: "success",
          duration: 10 * 1000,
          isClosable: true,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      if (!toast.isActive(errorFinishLoginToastId)) {
        toast({
          id: errorFinishLoginToastId,
          title: t("Login failed!"),
          description: t(
            "An error occured while logging in, please try again."
          ),
          status: "error",
          isClosable: true,
        });
      }
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
    }
    setIsFinishingLogin(false);
  };

  useEffect(() => {
    (async () => {
      const localStorageEmail = window.localStorage.getItem(emailForSignInKey);
      if (localStorageEmail !== null) {
        try {
          await signInWithEmailLink(
            auth,
            localStorageEmail,
            window.location.href
          );
          window.localStorage.removeItem(emailForSignInKey);
          if (!toast.isActive(finishLoginToastId)) {
            toast({
              id: finishLoginToastId,
              title: t("Logged in!"),
              description: t("You were successfully logged in!"),
              status: "success",
              duration: 10 * 1000,
              isClosable: true,
            });
          }
        } catch (error) {
          Sentry.captureException(error);
          if (!toast.isActive(errorFinishLoginToastId)) {
            toast({
              id: errorFinishLoginToastId,
              title: t("Login failed!"),
              description: t(
                "An error occured while logging in, please try again."
              ),
              status: "error",
              isClosable: true,
            });
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          }
        }
      }
      setHasCheckedForEmail(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box pt={20}>
      <Heading>{t("Finish login")}</Heading>
      <Text mt={4} maxW="xl">
        {t("Enter your email address again to finish the login process.")}
      </Text>

      <form
        onSubmit={(event) => {
          event.preventDefault();
          submitForm();
        }}
      >
        <Input
          mt={4}
          maxW="xl"
          value={email}
          type="email"
          onChange={handleChange}
          placeholder={t("Enter your email address...")}
        />
        <Button
          isLoading={!hasCheckedForEmail || isFinishingLogin}
          mt={2}
          display="block"
          type="submit"
          isDisabled={email.length === 0}
        >
          {t("Log in")}
        </Button>
      </form>
    </Box>
  );
};

export default FinishLogin;
