// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { isDevelopment } from "./values";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const developmentConfig = {
  apiKey: "AIzaSyBSasx0dz-9C-A2LDgyJExBkI6Rx7vVV1A",
  authDomain: "morgue-dev-9417c.firebaseapp.com",
  projectId: "morgue-dev-9417c",
  storageBucket: "morgue-dev-9417c.appspot.com",
  messagingSenderId: "995534117073",
  appId: "1:995534117073:web:3c5f5284b283ef02494df0",
};

// Your web app's Firebase configuration
const productionConfig = {
  apiKey: "AIzaSyArr6WOpIZOwFYmHj1rDv7VGt4GzO11FW8",
  authDomain: "morgue-143da.firebaseapp.com",
  projectId: "morgue-143da",
  storageBucket: "morgue-143da.appspot.com",
  messagingSenderId: "267173199654",
  appId: "1:267173199654:web:a54e7844792d626a5b8be7",
};

const firebaseConfig =
  isDevelopment || window.location.host.includes(developmentConfig.projectId)
    ? developmentConfig
    : productionConfig;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const functions = getFunctions(app, "europe-west1");

export default app;
