import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Container, Flex, Heading, IconButton } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import WelfareAreasDataContext from "../../contexts/WelfareAreasDataContext";
import useIsAreaMaintainer from "../../hooks/useIsAreaMaintainer";
import LanguageMenu from "./LanguageMenu";
import LogOutDialog from "./LogOutDialog";
import SettingsMenu from "./SettingsMenu";

/*
TODO: Further consider mobile-responsiveness of header.
*/
const Header = () => {
  const user = useUserContext();
  const { pathname } = useLocation();
  const welfareAreasData = useContext(WelfareAreasDataContext);
  const navigate = useNavigate();
  const firstWelfareAreaId = useMemo(
    () => welfareAreasData.welfareAreas[0]?.id,
    [welfareAreasData.welfareAreas]
  );
  const isWelfareAreaMaintainer = useIsAreaMaintainer(firstWelfareAreaId || "");
  const baseUrl =
    firstWelfareAreaId !== undefined
      ? `/areas/${firstWelfareAreaId}${
          isWelfareAreaMaintainer ? "" : "/morgues"
        }`
      : undefined;

  const canNavigateBack = baseUrl !== undefined && pathname !== baseUrl;

  return (
    <Box
      w="100%"
      minH={["48px", "64px"]}
      h={["48px", "64px"]}
      display="flex"
      justifyContent={"center"}
      alignItems="center"
      shadow="md"
    >
      <Container
        maxW={"container.xl"}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems={"center"}>
          {canNavigateBack ? (
            <IconButton
              aria-label="Navigate back"
              icon={<ArrowBackIcon fontSize="2xl" />}
              variant="ghost"
              onClick={() => navigate(-1)}
            />
          ) : null}
          <Link to="/">
            <Heading fontSize="2xl" color="tomato">
              Likförvaring
            </Heading>
          </Link>
        </Flex>
        <Box>
          <LanguageMenu />
          {user !== null && user !== undefined ? <SettingsMenu /> : null}
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
