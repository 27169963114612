import { useContext } from "react";
import { useAuthenticatedUser } from "../contexts/AuthenticatedUserContext";
import WelfareAreasDataContext from "../contexts/WelfareAreasDataContext";
import { getLocalizedName } from "./useLocalizedMorgueName";

const useAreaMorgues = (welfareAreaId: string): Morgue[] | undefined => {
  const { language } = useAuthenticatedUser();
  const welfareAreaData = useContext(WelfareAreasDataContext);
  const welfareAreaExists = welfareAreaData.welfareAreas.some(
    (welfareArea) => welfareArea.id === welfareAreaId
  );
  return !welfareAreaExists
    ? undefined
    : welfareAreaData.morgues
        .filter((morgue) => morgue.welfareArea === welfareAreaId)
        .sort((a, b) => {
          return getLocalizedName(a.name, language).localeCompare(
            getLocalizedName(b.name, language)
          );
        });
};

export default useAreaMorgues;
