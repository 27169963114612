import { Box, Button, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { useCallback, useContext } from "react";
import {
  useTranslation,
  withTranslation,
  WithTranslationProps,
} from "react-i18next";
import { useNavigate } from "react-router-dom";
import WelfareAreasDataContext from "../contexts/WelfareAreasDataContext";

const AreasPage = ({ i18n }: WithTranslationProps) => {
  const currentLanguage: Language =
    i18n !== undefined &&
    (i18n.language === "fi" || i18n.language === "sv" || i18n.language === "en")
      ? i18n.language
      : "fi";
  const { t } = useTranslation();
  const welfareAreasData = useContext(WelfareAreasDataContext);
  const navigate = useNavigate();

  const handleNavigateToWelfareArea = useCallback(
    (welfareAreaId: string, replace = false) => {
      navigate(welfareAreaId, { replace });
    },
    [navigate]
  );

  return (
    <Box py={10}>
      <Heading>{t("Your welfare areas")}</Heading>
      <Text mt={2}>
        {t("You can view all the areas you are a part of below.")}
      </Text>
      <SimpleGrid minChildWidth={"300px"} spacing={10} alignItems="center">
        {welfareAreasData.welfareAreas.map((welfareArea) => {
          return (
            <Button
              onClick={() => handleNavigateToWelfareArea(welfareArea.id)}
              key={`welfare-area-button-key-${welfareArea.id}`}
            >
              {welfareArea.name[currentLanguage]}
            </Button>
          );
        })}
      </SimpleGrid>
    </Box>
  );
};

export default withTranslation()(AreasPage);
