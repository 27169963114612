import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "../contexts/UserContext";

const useUnauthenticatedRedirect = () => {
  const user = useUserContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const isUserUnauthenticated = user === null;
    const isInLogin = pathname.startsWith("/login");
    const isInFinishLogin = pathname.startsWith("/finishlogin");
    const isNotInLogin = !(isInLogin || isInFinishLogin);

    if (isUserUnauthenticated && isNotInLogin) {
      navigate("/login");
    }
  }, [user, pathname, navigate]);
};

export default useUnauthenticatedRedirect;
