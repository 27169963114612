import { useTranslation } from "react-i18next";
import useLocalizedMorgueName from "../hooks/useLocalizedMorgueName";
import useMorgueData from "../hooks/useMorgueData";
import userNameString from "../utils/userNameString";

interface Props {
  specificLogData: MorgueUserUpdateLog;
}

const MorgueUserUpdateLogTableItem = ({ specificLogData }: Props) => {
  const { t } = useTranslation();
  const { isUpdatedToMorgueUser, updatedUser, morgueId } = specificLogData;
  const morgue = useMorgueData(morgueId);
  const morgueName = useLocalizedMorgueName(morgue?.name);

  const eventText = t(
    isUpdatedToMorgueUser ? "Added to morgue" : "Removed from morgue",
    {
      name: userNameString(updatedUser),
      morgueName,
    }
  );

  return <>{eventText}</>;
};

export default MorgueUserUpdateLogTableItem;
