import { useNavigate, useParams } from "react-router-dom";
import { Box, Heading, Button, Text, VStack, Stack } from "@chakra-ui/react";
import {
  useTranslation,
  withTranslation,
  WithTranslationProps,
} from "react-i18next";
import useIsAreaAdmin from "../hooks/useIsAreaAdmin";
import useIsAreaMaintainer from "../hooks/useIsAreaMaintainer";
import { useCallback, useContext, useMemo } from "react";
import UserListItem from "./UserListItem";
import CreateWelfareAreaUsersForm from "./CreateWelfareAreaUsersForm";
import WelfareAreasDataContext from "../contexts/WelfareAreasDataContext";
import PageNotFound from "../components/PageNotFound";
import CollapsibleInstructions from "../components/CollapsibleInstructions";
import { useAuthenticatedUser } from "../contexts/AuthenticatedUserContext";
import { scribeHowLinks } from "../config/values";

const AreaPage = ({ i18n }: WithTranslationProps) => {
  const { language } = useAuthenticatedUser();
  const { t } = useTranslation();
  const { area } = useParams();
  const welfareAreasData = useContext(WelfareAreasDataContext);
  const welfareArea = welfareAreasData.welfareAreas.find(
    (welfareArea) => welfareArea.id === area
  );
  const navigate = useNavigate();
  const isAreaAdmin = useIsAreaAdmin(area || "");
  const isAreaMaintainer = useIsAreaMaintainer(area || "");
  const currentLanguage: Language =
    i18n !== undefined &&
    (i18n.language === "fi" || i18n.language === "sv" || i18n.language === "en")
      ? i18n.language
      : "fi";

  const sortedWelfareAreaUsers = useMemo(
    () =>
      Object.values(welfareArea?.users || {}).sort((a, b) =>
        a.email.localeCompare(b.email)
      ),
    [welfareArea?.users]
  );

  const handleMorguesClick = useCallback(
    (replace = false) => {
      navigate("morgues", { replace });
    },
    [navigate]
  );

  const handleEventLogClick = useCallback(() => {
    navigate("logs");
  }, [navigate]);

  return welfareArea === undefined ? (
    <PageNotFound />
  ) : (
    <VStack py={10} spacing={8} alignItems="flex-start">
      <Box>
        <Heading>{welfareArea.name[currentLanguage]}</Heading>
      </Box>
      <Stack spacing={2} direction={["column", "row"]}>
        <Button onClick={() => handleMorguesClick()}>
          {t("Manage morgues in area")}
        </Button>
        <Button onClick={handleEventLogClick}>{t("Event log")}</Button>
      </Stack>
      <Box>
        <Heading variant="h3" size="lg">
          {t("Users")}
        </Heading>
        <Text mt={2} maxW="550px">
          {t(
            "Here you can add users to your welfare area, that you can give access to other parts of the system, such as updating the number of used spots within morgues.",
            { keySeparator: false }
          )}
        </Text>
        <Text mt={2} maxW="550px">
          {t(
            "Welfare area maintainers are used to reduce the workload of the admin Maintainers can do everything but add and remove other maintainers"
          )}
        </Text>
        <CollapsibleInstructions
          text={t("How do I add users?")}
          url={scribeHowLinks.addUser[language]}
        />
        <CreateWelfareAreaUsersForm welfareAreaId={welfareArea.id} />
        <VStack alignItems="flex-start" spacing={4} mt={2}>
          {sortedWelfareAreaUsers.map((user) => (
            <UserListItem
              canEditMaintainers={isAreaAdmin}
              canEditUsers={isAreaAdmin || isAreaMaintainer}
              isUserMaintainer={welfareArea.maintainers.includes(user.uid)}
              key={`welfare-area-user-key-${user.uid}`}
              user={user}
              welfareAreaId={welfareArea.id}
            />
          ))}
        </VStack>
      </Box>
    </VStack>
  );
};

export default withTranslation()(AreaPage);
